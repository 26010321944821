<template>
  <div>
    <div class="container padd-70">
      <!-- our story -->
      <div class="col-xs-12 col-sm-6 col-md-4 wow animated fadeInLeft">
        <div class="figure effect-steve section_div_cnt">
          <img src="@assets/img/Our Story.png" class="img-responsive" />
          <div class="figcaption text-center">
            <h2>{{ $lang._('our_story') }}</h2>
            <p>
              Our journey began with a simple yet ambitious...
              <a @click="navigateToWithParam('about_page', 'section', 'our_story')">{{ $lang._('read_more') }}</a>
            </p>
          </div>
        </div>
      </div>
      <!-- employee service -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="figure effect-steve section_div_cnt">
          <img src="@assets/img/Our services.png" class="img-responsive" />
          <div class="figcaption text-center">
            <h2>{{ $lang._('our services') }}</h2>
            <p>
              Fuel productivity and keep your team satisfied...
              <a @click="navigateToWithParam('about_page', 'section', 'our_services')" >{{ $lang._('read_more') }}</a>
            </p>
          </div>
        </div>
      </div>
      <!-- our vision -->
      <div class="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-0 col-md-4 wow animated fadeInRight">
        <div class="figure effect-steve section_div_cnt">
          <img src="@assets/img/Our vision.png" class="img-responsive" />
          <div class="figcaption text-center">
            <h2> {{ $lang._('our vision') }}</h2>
            <p>
              At Chaifing Dish we believe that great...
              <a @click="navigateToWithParam('about_page', 'section', 'our_vision')">{{ $lang._('read_more') }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <!-- welcome sectino -->
      <div class="col-xs-12 col-md-5 restora text-center">
        <div class="restora-icon">
          <i class="flaticon-dinner"></i>
        </div>
        <h3>{{ $lang._('Welcome to') }}</h3>
        <h2 class="content-subhead">Chaifing Dish</h2>
        <p>
        {{ $lang._('story_desc') }}
        </p>
        <a @click="navigateToWithParam('about_page', 'section', 'our_story')" class="btn-bor">{{ $lang._('read_more') }}</a>
      </div>
      <div class="col-xs-12 col-md-7 portfolio">
        <figure class="res-blc"></figure>

        <main>
          <a href="#" class="tilter tilter--1">
            <figure class="tilter__figure">
              <img class="tilter__image img-responsive" src="/theme_1/theme_restora/img/story-front.jpg" alt="img01" />
            </figure>
          </a>
        </main>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--  menu banner -->
    <div id="menu_section" class="container-fluid index-4-top-bg">
      <div class="index-4-top-bg-black">
        <div class="container">
          <div class="col-md-12 text-center">
            <div class="restora-icon">
              <i class="flaticon-dinner"></i>
            </div>
            <h3>TASTEFUL</h3>
            <h2>Recipes</h2>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="container padd-90">
      <!-- <div class="col-sm-12 col-md-3 wow animated fadeInLeft">
        <div class="col-sm-6 col-md-12">
          <div class="hover-img" style="margin-bottom: 30px">
            <img src="@assets/img/menu_categories/6.jpeg" class="image-hover" />
            <div class="overlay">
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 wow animated fadeInLeft">
          <div class="hover-img">
            <img src="@assets/img/menu_categories/5.jpeg" class="image-hover" />
            <div class="overlay">
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-sm-12 col-md-12 index-4 text-center">
        <div class="restora-icon">
          <i class="flaticon-dinner"></i>
        </div>
        <h3>DINNING WITH SEASON</h3>
        <h2 class="content-subhead">Our Menus</h2>
        <p>
          Founded by a team of passionate food enthusiasts, we set out to redefine the traditional sandwich and salad
          experience. Drawing inspiration from the vibrant culinary scenes around the world, we embarked on a mission to
          craft fresh,
          flavorful creations that delight the palate and nourish the body.
        </p>
        <a class="btn-bor" @click="navigateTo('menu_page')">
          VIEW THE MANU
        </a>
      </div>
      <!-- <div class="col-sm-12 col-md-3">
        <div class="col-sm-6 col-md-12 wow animated fadeInRight">
          <div class="hover-img" style="margin-bottom: 30px">
            <img src="@assets/img/menu_categories/1.jpeg" class="image-hover" />
            <div class="overlay">
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 wow animated fadeInRight">
          <div class="hover-img">
            <img src="/theme_1/theme_restora/img/index-4-4.jpg" class="image-hover" />
            <div class="overlay">
              <div class="text-h2">DRINKS</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSections",
  mounted() {

  },
}
</script>
<style>
.section_div_cnt{
  padding-top: 15px;
  text-align: center;
  img.img-responsive{
    display: inline;
    margin: inherit;
    height: 50px ;
    width: auto;
  }
  .figcaption{
    position: relative;
    display: inline-block;
  }
  .figure.effect-steve p a{
    margin-top: 0px;
  }
  .figcaption p{
    font-size: 12px;
    color: black;
  }
}

</style>
