// store.js
import { createStore } from 'vuex';
const store = createStore({
  state: {
    questionnaireTags: null,
    tags: null,
    eventTypes: null,
    clientDates: null, //client shop ordering dates
    cart: [], // Cart will be an object with dates as keys
    token: null,
    firstName: null
  },
  mutations: {
    setQuestionnaireTags: (state, data) => { state.questionnaireTags = data },
    setTags: (state, data) => { state.tags = data },
    setEventTypes: (state, data) => { state.eventTypes = data },
    setClientDates: (state, data) => { state.clientDates = data },
    toggleAccordion(state, dateIndex) {
      state.cart[dateIndex].hideAccordion = !state.cart[dateIndex].hideAccordion;
    },
    setOrderLineComment(state, { dateIndex, itemIndex, value }) {
      if (state.cart[dateIndex] && state.cart[dateIndex].items[itemIndex]) {
        state.cart[dateIndex].items[itemIndex].comment = value;
      }
    },
    setOrderLineSaveCustomization(state, { dateIndex, itemIndex, value }) {
      if (state.cart[dateIndex] && state.cart[dateIndex].items[itemIndex]) {
        state.cart[dateIndex].items[itemIndex].save_customization = value;
      }
    },
    SET_CART(state, newCart) {
      state.cart = newCart;
    },
    logout(state) {
      state.token = null;
      state.firstName = null;
      localStorage.removeItem('token');
      localStorage.removeItem('firstName');
    },
    login(state, { token, firstName }) {
      console.log('using state to login:', token, firstName)
      state.token = token;
      state.firstName = firstName
      localStorage.setItem('token', token);
      localStorage.setItem('firstName', firstName)
    }
  },
  actions: {
    // async 
    addToCartAndReturnIndex({ state, commit }, payload) {
      const { date, product } = payload;
      const dateIndex = state.cart.findIndex(item => item.date === date);

      let addedIndex = { dateIndex: -1, itemIndex: -1 };
      let newOrderLine = { product, quantity: 1, ingredients: [], additional: [], bread: {}, comment: '', save_customization: false };
      if (dateIndex === -1) {
        state.cart.push({ date, items: [newOrderLine] });
        addedIndex.dateIndex = state.cart.length - 1;
        addedIndex.itemIndex = 0;
      } else {
        state.cart[dateIndex].items.push(newOrderLine);
        addedIndex.dateIndex = dateIndex;
        addedIndex.itemIndex = state.cart[dateIndex].items.length - 1;
      }

      commit('SET_CART', state.cart); // Assuming SET_CART is your mutation to update cart
      return addedIndex; // Return the addedIndex object
    },
    changeIngredientByIndex({ state, commit }, payload) {
      const { orderLineIndex, Addedingredient, operation } = payload;
      if (!orderLineIndex) {
        console.log('Undefined index, return');
        return;
      }
      const dateIndex = orderLineIndex.dateIndex;
      const itemIndex = orderLineIndex.itemIndex;
      const orderLine = state.cart[dateIndex].items[itemIndex];
      if (orderLine) {
        const existingIngredientIndex = orderLine.ingredients.findIndex(
          ing => ing.id === Addedingredient.id
        );
        let item = state.cart[dateIndex].items[itemIndex];
        if (operation == 'add') {
          if (item.product.max_ingredients == item.ingredients.length) {
            return `You can only add ${item.product.max_ingredients} ingredients to ${item.product.name}`
          }
          if (existingIngredientIndex !== -1) {
            return (`You can only add the ingredient once to ${item.product.name}`)
            // For now, in the ingredients, the client can't add more than 1, to allow it uncomment the line below
            // item.ingredients[existingIngredientIndex].quantity++;
          } else {
            const newIngredient = { ...Addedingredient, quantity: 1 };
            item.ingredients.push(newIngredient);
          }
        } else if (operation == 'minus') {
          if (existingIngredientIndex !== -1) {
            if (item.ingredients[existingIngredientIndex].quantity > 0) {
              item.ingredients[existingIngredientIndex].quantity--;
              if (item.ingredients[existingIngredientIndex].quantity === 0) {
                item.ingredients.splice(existingIngredientIndex, 1);
              }
            }

          }
        }

      }
      commit('SET_CART', state.cart);
      return '200';
    },

    addAdditionalIngredientByIndex({ state, commit }, payload) {
      const { orderLineIndex, Addedingredient, operation } = payload;
      if (!orderLineIndex) {
        console.log('Undefined index, return');
        return;
      }

      const dateIndex = orderLineIndex.dateIndex;
      const itemIndex = orderLineIndex.itemIndex;

      const orderLine = state.cart[dateIndex].items[itemIndex];

      if (orderLine) {
        const existingIngredientIndex = orderLine.additional.findIndex(
          ing => ing.id === Addedingredient.id
        );
        if (operation == 'add') {
          if (existingIngredientIndex !== -1) {
            // Increment the quantity of the existing ingredient by one
            state.cart[dateIndex].items[itemIndex].additional[existingIngredientIndex].quantity++;
          } else {
            // Add the ingredient with quantity 1
            const newIngredient = { ...Addedingredient, quantity: 1 };
            state.cart[dateIndex].items[itemIndex].additional.push(newIngredient);
          }
        } else if (operation == 'minus') {
          if (existingIngredientIndex !== -1) {
            if (state.cart[dateIndex].items[itemIndex].additional[existingIngredientIndex].quantity > 0) {
              state.cart[dateIndex].items[itemIndex].additional[existingIngredientIndex].quantity--;
            }

          }
        }

      }

      commit('SET_CART', state.cart);
    },

    changeBreadByIndex({ state, commit }, payload) {
      const { orderLineIndex, SelectedBreadType } = payload;
      if (!orderLineIndex) {
        console.log('Undefined index, return');
        return;
      }

      const dateIndex = orderLineIndex.dateIndex;
      const itemIndex = orderLineIndex.itemIndex;

      const orderLine = state.cart[dateIndex].items[itemIndex];
      if (orderLine) {
        orderLine.bread = SelectedBreadType;
      }
      commit('SET_CART', state.cart);
    },

    changeProductQuantityByIndex({ state, commit }, payload) {
      const { dateIndex, itemIndex, newQuantity } = payload;
      if (dateIndex === undefined || itemIndex === undefined || newQuantity === undefined) {
        console.log('Undefined index or quantity, returning');
        return;
      }

      const orderLine = state.cart[dateIndex]?.items[itemIndex];

      if (orderLine) {
        state.cart[dateIndex].items[itemIndex].quantity = newQuantity;
      } else {
        console.log('Order line not found');
      }

      commit('SET_CART', state.cart);
    },

    removeProductFromCartByIndex({ state, commit }, payload) {
      const { dateIndex, itemIndex } = payload;
      if (state.cart[dateIndex] && state.cart[dateIndex].items[itemIndex]) {
        state.cart[dateIndex].items.splice(itemIndex, 1);
        if (state.cart[dateIndex].items.length === 0) {
          state.cart.splice(dateIndex, 1);
        }
      } else {
        console.log('Unable to remove product from cart: Item not found');
      }
      commit('SET_CART', state.cart);
    },
    fetchUserData({ commit }) {
      console.log('Try to fetch user data from local storage')
      const token = localStorage.getItem('token');
      const firstName = localStorage.getItem('firstName');
      if (token && firstName) {
        console.log('found, update state')
        commit('login', { token: token, firstName: firstName });
      }
    }

  },
  getters: {
    getQuestionnaireTags: (state) => state.questionnaireTags ?? [],
    getTags: (state) => state.tags,
    getEventTypes: (state) => state.eventTypes ?? [],
    GetClientDates: (state) => state.clientDates ?? [],

    // Return cart orderline based on date index and orderline index
    getCartOrderLine: (state) => (addedIndex) => {
      let required_orderLine = state.cart[addedIndex.dateIndex].items[addedIndex.itemIndex];
      return required_orderLine;
    },
    getCart: (state) => state.cart ?? [],
    isCartEmpty: (state) => {
      return state.cart.length === 0;
    },
    // Retreive current quantity for a given ingredient in a given orderline
    ingredientQuantity: (state) => (payload) => {
      const { orderLineIndex, ingredient } = payload;
      const dateIndex = orderLineIndex.dateIndex;
      const itemIndex = orderLineIndex.itemIndex;
      if (!(state.cart[dateIndex] && state.cart[dateIndex].items[itemIndex])) {
        return 0
      }
      const orderLine = state.cart[dateIndex].items[itemIndex];

      if (orderLine) {
        const existingIngredientIndex = orderLine.ingredients.findIndex(
          ing => ing.id === ingredient.id
        );
        if (existingIngredientIndex !== -1) {
          return orderLine.ingredients[existingIngredientIndex].quantity;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    additionalQuantity: (state) => (payload) => {
      const { orderLineIndex, ingredient } = payload;
      const dateIndex = orderLineIndex.dateIndex;
      const itemIndex = orderLineIndex.itemIndex;
      if (!(state.cart[dateIndex] && state.cart[dateIndex].items[itemIndex])) {
        return 0
      }
      const orderLine = state.cart[dateIndex].items[itemIndex];

      if (orderLine) {
        const existingIngredientIndex = orderLine.additional.findIndex(ing => ing.id === ingredient.id);
        if (existingIngredientIndex !== -1) {
          return orderLine.additional[existingIngredientIndex].quantity;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    orderLineTotal: (state) => (dateIndex, olIndex) => {
      let date = state.cart[dateIndex];
      let orderline = date.items[olIndex];
      let total = 0;
      // Calculate the total including product price
      total += parseFloat(orderline.product.price);
      // // Calculate the total including ingredient prices
      orderline.ingredients.forEach((ingredient) => {
        total += parseFloat(ingredient.price) * ingredient.quantity;
      });

      // // Calculate the total including additional item prices
      orderline.additional.forEach((additional) => {
        total += parseFloat(additional.price) * additional.quantity;
      });
      if (orderline.bread && orderline.bread.id) {
        total += parseFloat(orderline.bread.price)
      }
      total = total * orderline.quantity;
      total = Math.round(total * 100) / 100;
      return total;
    },
    cartTotal: (state, getters) => {
      let total = 0;

      state.cart.forEach((date, dateIndex) => {
        // Iterate over each item in the date
        date.items.forEach((orderline, olIndex) => {
          // Calculate the total for the order line using the orderLineTotal getter
          total += getters.orderLineTotal(dateIndex, olIndex);
        });
      });

      total = Math.round(total * 100) / 100;
      return total;
    },
    authUser: (state) => {
      console.log('check auth User in store')
      console.log(state.token)
      console.log(state.firstName)
      if (state.token && state.firstName) {
        return { token: state.token, name: state.firstName };
      } else { //todo if it find only token, it should check if it is valid, and retreive firstName from server using new API
        return null;
      }
    }
  }
});

export default store;
