<template>
  <div class="col-md-12 col-xs-12 col-sm-12 nopadd">
    <h2 class="month">
      <b style="font-family: Pacifico">Cart</b>
    </h2>
    <div class="deleviery">
      <h5>Delivering at</h5>
      <span><img src="@assets/img/Scooter.png" /><span>Street 42, 1150 Watermael-Boitsfort
        </span></span>
    </div>
    <div v-if="!$store.getters.isCartEmpty">
      <div class="buttons">
        <div class="col-md-12 col-xs-12 element-faq nopadd" v-for="(dateItem, dateIndex) in $store.getters.getCart"
          :key="dateIndex">
          <div class="accordion" :id="'section' + dateIndex">
            <span class="dateName">{{ formatDate(dateItem.date) }}
              <span class="numItems">({{ dateItem.items.length }})</span>
            </span>
            <span class="dateArrow" @click="toggleAccordion(dateIndex)">
              <i :class="{ 'fa fa-angle-down': dateItem.hideAccordion, 'fa fa-angle-up': !dateItem.hideAccordion }"
                aria-hidden="true"></i>
            </span>
          </div>
          <div class="accordian-body" v-if="dateItem.items.length > 0 && !dateItem.hideAccordion">
            <div class="faq">
              <table class="element-table cart_element_table">
                <tr v-for="(orderline, olIndex) in dateItem.items" :key="olIndex" class="input-close">
                  <td>
                    <span>
                      <b>{{ orderline.product.name }}</b>
                      <span v-if="orderline.comment !== ''" class="" :title="orderline.comment">
                        <i class="fa fa-comment-o" aria-hidden="true"></i>
                      </span>
                      <span v-if="orderline.save_customization" class="" title="Will be saved for next time">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i>
                      </span>
                    </span>
                    <br>
                    <span v-if="(orderline.bread.id) && (!orderline.bread.is_default)">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      {{ orderline.bread.name }}
                      <br>
                    </span>

                    <span v-for="ingredient in orderline.ingredients" :key="ingredient.id">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      {{ ingredient.name }} [{{ ingredient.quantity }}]
                      <br>
                    </span>

                    <span v-for="ingredient in orderline.additional" :key="ingredient.id">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      {{ ingredient.name }} [{{ ingredient.quantity }}]
                      <br>
                    </span>
                  </td>
                  <td>
                    <div class="quantity">
                      <button @click="decreaseQty(dateIndex, olIndex, orderline)" class="minus-btn" type="button"
                        name="button" :disabled="orderline.quantity == 1">
                        <i class="fa fa-minus-square" aria-hidden="true"></i>
                      </button>

                      <input readonly :value="orderline.quantity" />

                      <button @click="increaseQty(dateIndex, olIndex, orderline)" class="plus-btn add_icon"
                        type="button" name="button">
                        <i class="fa fa-plus-square" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="cart_last_line">
                      {{ $store.getters.orderLineTotal(dateIndex, olIndex) }}€
                      <a class="remove_cart_btn" @click="removeFromCart(dateIndex, olIndex)">
                        <i class="fa fa-trash content-subhead" aria-hidden="true"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="cart_total col-md-12 col-xs-12 nopadd element-faq">
        <div class="col-md-6 col-xs-6">
          <span>Total:</span>
        </div>
        <div class="col-md-6 col-xs-6 text-right">
          <span> {{ $store.getters.cartTotal }}€</span>
        </div>
        <div class="col-md-12 col-xs-12 element-faq">
          <a @click="placeOrder" class="orderButton" :class="{ disabled: isPlacingOrder }">Checkout</a>
        </div>
      </div>
    </div>
    <!-- this view will be visible onle in cart page, in home page the cart will not be visible -->
    <div v-else>
      <div class="cart_total col-md-12 col-xs-12 nopadd element-faq">

        <div class="col-md-12 col-xs-12 element-faq">
          <a class="orderButton disabled">Your cart is empty</a>
        </div>

      </div>
    </div>

    <div class="hidden-md col-xs-12 col-sm-12 hidden-lg element-faq">
      <a class="orderButton" @click="this.navigateTo('client_shop_page');">Back to Shop</a>
    </div>


  </div>
</template>

<script>
import axios from 'axios';
// import { useToast } from "vue-toastification";
export default {
  name: "Cart",
  components: {},

  methods: {
    formatDate(dateString) {
      const options = { weekday: "long", day: "numeric", month: "long" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },

    increaseQty(dateIndex, orderLineIndex, obj) {
      console.log(obj)
      this.$store.dispatch("changeProductQuantityByIndex", {
        dateIndex: dateIndex,
        itemIndex: orderLineIndex,
        newQuantity: obj.quantity + 1
      })
    },
    decreaseQty(dateIndex, orderLineIndex, obj) {
      console.log(obj)
      if (obj.quantity > 1) {
        this.$store.dispatch("changeProductQuantityByIndex", {
          dateIndex: dateIndex,
          itemIndex: orderLineIndex,
          newQuantity: obj.quantity - 1
        })
      }
    },
    removeFromCart(dateIndex, orderLineIndex) {
      const payload = {
        dateIndex: dateIndex,
        itemIndex: orderLineIndex
      };

      this.$store.dispatch("removeProductFromCartByIndex", payload);
    },
    toggleAccordion(dateIndex) {
      this.$store.commit('toggleAccordion', dateIndex);
    },

    async placeOrder() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      let link = `${baseUrl}orders/api/submitOrder/`
      console.log(link);
      if (this.isPlacingOrder) return; // Prevent multiple clicks
      // return
      this.isPlacingOrder = true;
      console.log(this.$store.getters.getCart)
      try {
        const cart = this.$store.getters.getCart;
        let token = this.$store.getters.authUser.token;
        await axios.post(link, { cart: cart, userId: 1 }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }).then(result => {
          console.log(result)
          if (result && result.data && result.data.success) {
            // console.log('Order submitted successfully:', result.data.payment_link);
            // window.open(result.data.payment_link, '_blank');
            window.open(result.data.payment_link);
          }
          this.isPlacingOrder = false;
        })
      } catch (error) {
        console.error('Error submitting order');
        if (error.response) {
          // The request was made and the server responded with a status code
          console.log(error.response.data);
          if (error.response.data.error) {
            this.raiseAlert('', error.response.data.error);
          } else {
            let status = error.response.status;
            this.raiseAlert('', 'Error submitting your order server said:' + status);
          }
          console.log(error.response.status);
          console.log(error.response.headers);

        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          this.raiseAlert(false, 'No response from server');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          this.raiseAlert(false, 'Unable to validate your order');
        }
        console.log(error)
        this.isPlacingOrder = false;

      }
    }
  },
  data() {
    return {
      isPlacingOrder: false
    }
  }
};
</script>

<style scoped>
.orderButton.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>