<template>
  <div>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" />
    <div id="wrapper">
      <HeaderComponent />
      <page_header title="Checkout" subtitle="DELICIOUS FOOD. TO FIT YOUR LIFESTYLE."
        pageName="Place your order" :short_header="true"/>


      <section class="wow animate fadeInUp">
        <div class="container-fluid padd-bottom">
          <div class="row">
            <div  class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-8 col-xs-offset-2 cart padd-50">
              <Cart  ref="cartComponent" />
            </div>
           
          </div>
        </div>
      </section>

      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/views/components/Header";
import FooterComponent from "@/views/components/Footer";
import page_header from "@/views/components/page_header";
import Cart from "./Cart";

export default {
  name: "ClientCartPage",
  components: {
    HeaderComponent,
    page_header,
    FooterComponent,
    Cart,
  },

  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    cartPlaceOrder() {
      // Call the placeOrder method inside the Cart component
      this.$refs.cartComponent.placeOrder();
    }
  },
  computed: {

  },
};
</script>
