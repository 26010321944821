<template>
  <div>
    <div id="wrapper">
      <HeaderComponent />
      <BannerComponent v-if="loadedBanner" :loadedBanner="loadedBanner" />
      <Sections />
      <ImageAndCounter />
      <Events />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import Questionnaire from "@/views/components/Questionnaire";
import HeaderComponent from "@/views/components/Header";
import BannerComponent from "@/views/components/Banner.vue"
import Sections from "@/views/home/Sections"
import ImageAndCounter from "@/views/home/ImageAndCounter"
import Events from "@/views/home/Events"
import FooterComponent from '@/views/components/Footer'
import Product from '@/views/home/Products'
import GetBanner from "@/composables/GetBanner"
export default {
  name: "HomeView",
  components: {
    Questionnaire,
    HeaderComponent,BannerComponent,Sections,ImageAndCounter,Events,Product,
    FooterComponent
  },
  data() {
    return {
      home_error: null,
      isLoading: true,
      data_error: null,
      loadedBanner: null,
    };
  },

  created() {
    this.loadBannerData();
  },
  methods:{
    async loadBannerData() {
      const banner = GetBanner();
      try {
        await banner.loadBanner();
        if (!this.isRefEmpty(banner.loadedBanner)) {
          this.loadedBanner = banner.loadedBanner.value.map(banner => ({
            id: banner.id,
            index: `rs-${banner.id}`,
            title: banner.title,
            description: banner.description,
            image: banner.image,
          }));
        }
      } catch (error) {
        this.data_error = error.message;
      }
    },
  }
};
</script>

<style scoped>

</style>
