<template>
  <div id="wrapper">
    <HeaderComponent />
    <page_header title="About Us" subtitle="Our Adventure,Our Art, Our Philosophy" pageName="ABOUT" />
    <div class="container padd-70">
      <div class="col-md-6" id="our_story">
        <h2 class="story-top-head">Our Story</h2>
        <p class="story-top-head-p">
          <b class="story-top-head-b content-subhead"> Our journey</b> began with a simple yet ambitious vision: to revolutionize the way people experience food in their
          everyday lives.
        </p>
        <p class="story-top-head-p">
          It all began with a simple dream
          - to share the joy of good food and the warmth of hospitality with
          everyone we serve.
        </p>
        <p class="story-top-head-p">
          Founded by a team of passionate food enthusiasts, we set out to redefine the traditional sandwich and salad
          experience. Drawing inspiration from the vibrant culinary scenes around the world, we embarked on a mission to
          craft fresh,
          flavorful creations that delight the palate and nourish the body.
        </p>
        <p class="story-top-head-p">
          But we didn't stop there. We recognized the importance of seamlessly integrating technology into the dining
          experience, making it easier and more convenient for our customers to enjoy delicious meals anytime, anywhere.
          Through our innovative online platform, ordering your favorite sandwiches and salads has never been simpler.
          Whether you're at the office, working from home, or hosting a private event, we've got you covered.
        </p>
       
      </div>
      <div class="col-md-6 text-center">
        <img src="/theme_1/theme_restora/img/story-top.jpg" style="display: inline-block"
          class="img-responsive story-top-head-img" />
      </div>
      <div class="clearfix"></div>
      
      <div class="col-md-12" id="our_services">
        <h2 class="story-top-head story-mission">Our Services</h2>
        <p class="story-top-head-p">
          <b class="story-top-head-b content-subhead"> Office Delivery:</b>
           Fuel productivity and keep your team satisfied with our convenient office delivery
          service.
          From hearty sandwiches to refreshing salads, we offer a diverse menu of fresh and flavorful options to suit
          every taste bud.
        </p>
        <p class="story-top-head-p"><b class="story-top-head-b content-subhead">Private Events:</b> 
          Elevate your next event with our catering services. Whether you're planning a corporate luncheon, a team
          meeting, or a special celebration, our expert team will work with you to create a customized menu that exceeds
          your expectations. We use only the finest ingredients to deliver a dining experience that will leave a lasting
          impression on your guests.
        </p>
        <p class="story-top-head-p">
          <b class="story-top-head-b content-subhead">Customization Options:</b>
        
          At Chaifing Dish, we believe that every palate is unique. That's why we offer a
          range of customization options to ensure that your meal is tailored to your tastes and dietary preferences.
          Whether you're vegan, gluten-free, or just craving something extra spicy, our team will gladly accommodate
          your requests to create a meal that's truly one-of-a-kind.
        </p>
        <p class="story-top-head-p">
          <b class="story-top-head-b content-subhead">Tech Integration:</b>
       
          We're passionate about leveraging technology to enhance the dining experience. From our
          intuitive online ordering platform to our seamless delivery tracking system, we're committed to making the
          process as convenient and efficient as possible for our customers. With Chaifing Dish, enjoying delicious food
          has never been easier.
        </p>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-12" id="our_vision">
        <h2 class="story-top-head story-mission">The Art of Cooking</h2>
        <p class="story-top-head-p">
          <b class="story-top-head-b content-subhead"> At Chaifing Dish</b> we believe that great food brings people together.
           That's why we're not just in the business of
          serving sandwiches and salads – we're in the business of creating memorable moments.
          Whether you're fueling up for a busy workday or celebrating a special occasion with friends and colleagues,
          we're here to make every bite an experience to savor.
          Join us on our culinary journey and discover why Chaifing Dish is more than just a meal – it's a celebration
          of flavor, innovation, and community.

        </p>
      </div>
      <div class="clearfix"></div>
    </div>
    <FooterComponent />
  </div>
</template>
<script>
import HeaderComponent from "@/views/components/Header";
import ImageAndCounter from "@/views/home/ImageAndCounter";
import FooterComponent from "@/views/components/Footer";
import page_header from "@/views/components/page_header";
export default {
  name: "AboutView",
  components: {
    HeaderComponent,
    ImageAndCounter,
    page_header,
    FooterComponent,
  },
  
};
</script>