<template>
  <div :class="['head-img', 'text-center', { 'short-header-class': short_header }]">
    <div class="story-head-black" v-if="!short_header">
      <div class="container">
        <h2 v-if="title">{{ title }}</h2>
        <p>{{ subtitle }}</p>
        <span>
          <router-link to="/"> {{ $lang._("home") }} </router-link>
          &nbsp; >&nbsp;
          <b class="content-subhead">{{ pageName }}</b></span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "page_header_for_client",
  props: {
    title: false,
    subtitle: false,
    pageName: false,
    short_header: Boolean,
  },
};
</script>

<style scoped>

</style>