<template>
  <div>
    <div id="wrapper">
      <HeaderComponent />
      <page_header title="Event Planning" subtitle="Simple - Easy - Customized Suggestions" pageName="event"/>
      <Questionnaire />
      <ImageAndCounter />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import Questionnaire from "@/views/components/Questionnaire";
import HeaderComponent from "@/views/components/Header";
import ImageAndCounter from "@/views/home/ImageAndCounter"
import FooterComponent from '@/views/components/Footer'
import page_header from '@/views/components/page_header'
export default {
  name: "CatermateView",
  components: {
    Questionnaire,
    HeaderComponent,ImageAndCounter,page_header,
    FooterComponent
  },
  data() {
    return {
      // loadedHomeSections: [],
      home_error: null,
      isLoading: true,
    };
  },

  mounted() {
  }
};
</script>

<style scoped>

</style>
