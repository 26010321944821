import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetMenu = () => {

  const loadedMenuItems = ref([])
  const loadedMenuTags = ref([])
  const loadedMenuCategories = ref([])
  const data_error = ref(null)

  const loadMenuCategories = async() => {
    try {
      let apiUrl = `${baseUrl}products/api/menu/categories/`;
      let response = await fetch(apiUrl);

      if (!response.ok) {
        throw Error('No available home products');
      }

      let data = await response.json();

      if (data.success) {
        loadedMenuCategories.value = data.data;
        console.log('Data was fetched from menu categories:')
        console.log(loadedMenuCategories.value)
      } else {
        console.log('Unable to load menu categories');
        console.log(data);
      }
    }
    catch (err) {
      data_error.value = err.message
    }
  }
  const loadMenuItems = async () => {
    try {
      let apiUrl = `${baseUrl}products/api/menu/items/`;
      let response = await fetch(apiUrl);

      if (!response.ok) {
        throw Error('No available home products');
      }

      let data = await response.json();

      if (data.success) {
        loadedMenuItems.value = data.data;
        console.log('Data was fetched from menu items:')
        console.log(loadedMenuItems.value)
      } else {
        console.log('Unable to load menu items');
        console.log(data);
      }
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  const loadMenuTags = async () => {
    try {
      let apiUrl = `${baseUrl}products/api/menu/tags/`;
      let response = await fetch(apiUrl);

      if (!response.ok) {
        throw Error('Failed to load menu tags');
      }

      let data = await response.json();
      if (data.success) {
        // Assuming tags are available in response.data.tags
        loadedMenuTags.value = data.data;
        console.log('Loaded tags successfully')
        console.log(loadedMenuTags.value)
      } else {
        throw Error('Unable to load menu tags');
      }
    } catch (error) {
      data_error.value = error.message;
    }
  }
  // return { loadedMenuItems, data_error, LoadMenuItems }
  return {
    loadedMenuItems,
    loadedMenuTags,
    loadMenuCategories,
    data_error,
    loadMenuItems,
    loadMenuTags,
    loadedMenuCategories
  };
}

export default GetMenu