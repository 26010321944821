<template>
    <div id="wrapper">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" />
        
            <HeaderComponent />
            <page_header title="Contact us" subtitle="Send us a message and we'll get back to you soon"
                pageName="Sent us a message" />

            <div class="container padd-50">
                <div class="col-md-8 contact-page-head-border">
                    <Contact_form />
                </div>

                <div class="col-md-4">
                    <div class="row margin-top">
                        <div class="col-md-offset-2 col-md-10">
                            <div class="contact-page-head">
                                <h2>Contact Information</h2>
                                <div class="contact-head-u btn-bg"></div>
                            </div>
                            <!-- <div class="contact-det">
                                <div class="contact-icon"><i class="flaticon-placeholder"></i></div>
                                <span>Address <br />
                                    <p>Belgium</p>
                                </span>
                            </div> -->
                            <div class="contact-det">
                                <div class="contact-icon"><i class="flaticon-phone-call"></i></div>
                                <span>PHONE <br />
                                    <p style="margin-bottom:8px;"><b>+32 467 65 47 04</b></p>
                                    <p><b>+32 466 21 05 67</b></p>
                                </span>
                            </div>
                            <div class="contact-det">
                                <div class="contact-icon"><i class="flaticon-envelope"></i></div>
                                <span>EMAIL <br />
                                    <p>
                                        <a class="mail_btn" href="mailto:infochaifingdish.com">
                                            infochaifingdish.com
                                        </a>
                                    </p>
                                </span>
                            </div>
                            <div class="contact-det">
                                <div class="contact-icon"><i class="flaticon2-clock"></i></div>
                                <span>OPENING HOURS <br />
                                    <p>Mon-Sat : 8:00AM - 7:30PM</p>
                                    <p>Sunday :- <em class="content-subhead">Closed</em></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
       
        <FooterComponent />
    </div>

</template>

<script>
import HeaderComponent from "@/views/components/Header";
import page_header from "@/views/components/page_header";
import FooterComponent from "@/views/components/Footer";
import Contact_form from "@/views/components/Contact_form";
export default {
    name: "Contact",
    components: {
        HeaderComponent,
        page_header,
        FooterComponent,
        Contact_form,
    },
    data() {
        return {
        }
    },
    methods: {
    }
};
</script>
