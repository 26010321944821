<template>
  <div>
    <!-- Events Photo -->
    <div class="container padd-90">
      <div class="col-md-6">
        <div
          class="col-xs-6 col-sm-6 col-md-6 margin-btm-img wow animated fadeInDown"
        >
          <img
            src="/theme_1/theme_restora/img/index-4-btm-3.jpg"
            class="img-responsive"
          />
        </div>
        <div
          class="col-xs-6 col-sm-6 col-md-6 margin-btm-img wow animated fadeInUp"
        >
          <img
            src="/theme_1/theme_restora/img/index-4-btm-4.jpg"
            class="img-responsive"
          />
        </div>
      </div>
      <div
        class="col-xs-12 col-md-6 restora text-center"
        style="padding-top: 0; padding-bottom: 0"
      >
        <div class="restora-icon">
          <i class="flaticon-dinner"></i>
        </div>
        <h3>Events with Chaifing Dish</h3>
        <h2 class="content-subhead">Private events</h2>
        <p>
          Elevate your next event with our catering services. Whether you're planning a corporate luncheon, a team
          meeting, or a special celebration, our expert team will work with you to create a customized menu that exceeds
          your expectations. We use only the finest ingredients to deliver a dining experience that will leave a lasting
          impression on your guests.
        </p>
        <a class="btn-bor" @click="navigateTo('catermate_page')">Use our event helper</a>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default{
  name: "HomeEvents",
  mounted() {
    
  },
}
</script>