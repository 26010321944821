import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import LoaderPlugin from './mixins/loaderPlugin' // no brackets means imports the default export
import { commonMixin } from './mixins/commonMixin'; // brackets means imports a named export named 'commonMixin' 
import langs from './mixins/langs';
import './assets/css/styles.css';
import './assets/css/custom.css'
import './assets/css/custom_responsive.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import './assets/mega_menu.js'
const app = createApp(App);
app.use(router);
app.use(store)
app.use(LoaderPlugin);
app.mixin(commonMixin);
app.use(Toast, {}); // {option default}
app.config.globalProperties.$lang = langs;
langs.init().then(() => {
  app.mount('#app');
});

const enableConsole = process.env.VUE_APP_enable_console === 'true';
// Overwrite console.log if enableConsole is true
if (!enableConsole) {
  console.log = function () {
    // don't show console
  };
} 