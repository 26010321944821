<template>
  <div v-show="isLoading" class="loader-overlay">
    <div class="loader"></div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    isLoading: false,
  },
  components: {},
  mounted() {},
};
</script>



<style scoped>
/* Add the provided styles here */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid var(--primary); /* Primary color border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>