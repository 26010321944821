import { useToast } from "vue-toastification";
export const commonMixin = {
  methods: {
    isRefEmpty(ref) {
      if (!ref.value) {
        return true;
      }
      if (Array.isArray(ref.value) && ref.value.length === 0) { // if value is empty array
        return true;
      }
      return false;
    },
    isEmpty(param) {
      if (!param)
        return true;
      if (Array.isArray(param) && param.length === 0)
        return true;
      return false;
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },

    navigateToWithParam(routeName, paramName, paramValue) {
      console.log('Nvigate with params: ', routeName, paramName, paramValue)
      this.$router.push({
        name: routeName,
        query: { [paramName]: paramValue }
      });
    },
    getEmbeddedUrl(youtubeLink) {
      const videoId = youtubeLink.split("v=")[1];
      // Construct the embedded URL
      console.log('get youtube id from link')
      console.log(youtubeLink)
      console.log(videoId)
      return `https://www.youtube.com/embed/${videoId}`;
    },
    getThumbnailUrl(youtubeLink) {
      const videoId = youtubeLink.split("v=")[1];
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    },
    raiseAlert(type, msg) {
      const toast = useToast();
      switch (type) {
        case 'success':
          toast.success(msg, {
            timeout: 2000
          });
          break;
        case 'warning':
          toast.warning(msg, {
            timeout: 2000
          });
          break;

        default:
          toast.error(msg);
          break;
      }
    },
    scrollToSection(sectionId) {
      let scrollToTop = true;
      if (sectionId && sectionId != undefined) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          scrollToTop = false
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      if(scrollToTop)
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
  },
};