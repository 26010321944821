<template>
  <div class="container-fluid home-2-bg">
    <div class="container padd-70">
      <div class="text-center col-md-12 col-sm-12 col-xs-12">
        <div class="restora-icon"><i class="flaticon-dinner"></i></div>
        <h3 style="font-weight: 700; font-size: 22px; color: #fff">
          Top Picks: Most Popular Salads & Sandwiches
        </h3>
        <h2 style="color: #fff">Most Popular</h2>
        <p style="color: #fff; max-width: 500px; display: inline-block">
        </p>
        <span class="contact-underline"> </span>
      </div>
      <div class="clearfix"></div>
      <div class="content">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <Product_cell v-for="prod in products" :key="prod.id"  :prod="prod"/>
        </div>
        <div class="preview">
          <button class="action action--close">
            <i class="fa fa-times"></i><span class="text-hidden">Close</span>
          </button>
          <div class="description description--preview"></div>
        </div>
      </div>
      <div class="text-center col-md-12 col-sm-12 col-xs-12">
        <a @click="navigateToWithParam('shop','cat',3)"  class="btn-bg menu-all-btn">VIEW ALL</a>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import {watch} from "vue"
import Product_cell from '@/views/components/Product_cell'

import GetProducts from "@/composables/GetProducts"
export default{
components: {
  Product_cell
  },
  data(){
    return{
      products:[],
      products_error:null,
    }
  },
  mounted() {
    console.log('page prod mounted')
    this.loadproducts();
  },
  methods:{
    loadproducts() {
      console.log('load products')
      let { loadedData, data_error, loadProductData } = GetProducts();
      watch([loadedData, data_error], () => {
        if (!this.isRefEmpty(data_error)) {
          this.products_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          console.log("product loaded");
          this.products = loadedData.value;
          console.log(this.products);
        }
      });
      loadProductData('2,3',4);
    },
  }
}

</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .mz_mobile {
    width: 270px;
    margin: auto;
  }
}
</style>