<template>
  <section class="slider">
    <div id="rev_slider_3_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container"
      data-alias="the-The Chaifing Dish" style="
        margin: 0px auto;
        background-color: transparent;
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      ">
      <div ref="sliderContainer" id="rev_slider_3_1" class="rev_slider fullwidthabanner" style="display: none"
        data-version="5.2.6">
        <ul v-if="loadedBanner">
          <li v-for="banner in loadedBanner" :key="banner.id" :data-index="banner.index" data-transition="fade"
            data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
            data-easeout="default" data-masterspeed="300" :data-thumb="banner.image" data-rotate="0"
            data-saveperformance="off" data-title="Slide">
            <img :src="banner.image" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
              class="rev-slidebg" data-no-retina />
            <div class="tp-caption text-orange tp-resizeme text-center" :id="'slide-' + banner.id + '-layer-14'"
              data-x="center" data-hoffset="" data-y="295" data-width="['auto']" data-height="['auto']"
              data-transform_idle="o:1;" data-transform_in="y:50px;opacity:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;" data-start="500" data-splitin="none" data-splitout="none"
              data-responsive_offset="on" style="
                z-index: 13;
                white-space: nowrap;
                font-size: 58px;
                line-height: 65px;
                font-weight: 400;
                font-family: pacifico;
                color: #fff;
              ">
            </div>
            <div class="tp-caption text-orange tp-resizeme text-center" :id="'slide-' + banner.id + '-layer-1'"
              data-x="center" data-hoffset="" data-y="395" data-width="['auto']" data-height="['auto']"
              data-transform_idle="o:1;" data-transform_in="y:50px;opacity:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;" data-start="1000" data-splitin="none" data-splitout="none"
              data-responsive_offset="on" style="
                z-index: 13;
                white-space: nowrap;
                font-size: 58px;
                line-height: 65px;
                font-weight: 400;
                font-family: pacifico;
                color: #fff;
              ">
              {{ banner.title }}
            </div>
            <div class="tp-caption tp-resizeme mo-details-text" :id="'slide-' + banner.id + '-layer-2'" data-x="center"
              data-hoffset="" data-y="485" data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;"
              data-transform_in="y:-50px;opacity:0;s:2000;e:Power2.easeInOut;" data-transform_out="opacity:0;s:300;"
              data-start="1500" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="
                z-index: 8;
                white-space: nowrap;
                font-size: 20px;
                letter-spacing: 2px;
                line-height: 24px;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
                font-family: raleway;
                text-align: center;
                text-transform: uppercase;
              ">
              {{ banner.description }}
            </div>
            <div class="tp-caption tp-resizeme mo-sub-title-text" :id="'slide-' + banner.id + '-layer-3'"
              data-x="center" data-hoffset="" data-y="510" data-width="['auto']" data-height="['auto']"
              data-transform_idle="o:1;" data-transform_in="y:-50px;opacity:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="opacity:0;s:300;" data-start="2000" data-splitin="none" data-splitout="none"
              data-responsive_offset="on" style="line-height: 20px; font-weight: bold">
              <router-link to="/about" class="slider-btn-book btn-bg-slider">
                More
              </router-link>
              <a @click="scrollToElement('menu_section')" class="slider-btn-about">Menu</a>
            </div>
          </li>
        </ul>
        <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important"></div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: "BannerComponent",
  mounted() {
    this.startSlider();
  },
  props: {
    loadedBanner: null,
  },
  data() {
    return {}
  },
  methods: {
    startSlider() {
      let slider = jQuery(this.$refs.sliderContainer);
      if (slider.length === 0 || typeof slider.revolution === "undefined") {
        console.error(
          "Slider initialization failed. Slider container not found or Revolution Slider script not loaded."
        );
        return;
      }

      slider.show().revolution({
        sliderType: "standard",
        sliderLayout: "fullwidth",
        dottedOverlay: "none",
        delay: 9000,
        navigation: {
          keyboardNavigation: "off",
          keyboard_direction: "horizontal",
          mouseScrollNavigation: "off",
          mouseScrollReverse: "default",
          onHoverStop: "off",
          touch: {
            touchenabled: "on",
            swipe_threshold: 75,
            swipe_min_touches: 1,
            swipe_direction: "horizontal",
            drag_block_vertical: false,
          },
          bullets: {
            enable: true,
            hide_onmobile: false,
            style: "ares",
            hide_onleave: false,
            direction: "horizontal",
            h_align: "right",
            v_align: "bottom",
            h_offset: 40,
            v_offset: 40,
            space: 15,
          },
        },
        visibilityLevels: [1240, 1024, 778, 480],
        gridwidth: 1170,
        gridheight: 785,
        lazyType: "none",
        shadow: 0,
        spinner: "spinner2",
        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,
        shuffle: "off",
        autoHeight: "off",
        disableProgressBar: "on",
        hideThumbsOnMobile: "off",
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLimit: 0,
        debugMode: false,
        fallbacks: {
          simplifyAll: "off",
          nextSlideOnWindowFocus: "off",
          disableFocusListener: false,
        },
      });
    },
    scrollToElement(selectorID) {
      const targetElement = document.getElementById(selectorID);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },

  },
};
</script>