<template>
  <div>
    <div id="myModal" class="modal" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="new-label">{{ $lang._('Added') }} </div>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <!-- <div class="modal-header">
            
            <h4 v-if="prod" class="modal-title"></h4>
          </div> -->
          <div class="modal-body" v-if="prod">
            <div class="itemContaier">
              <div class="item col-md-6 col-sm-6 col-xs-12 shop text-center left_section">
                <div>
                  <figure>
                    <img alt="1-image" :src="prod.image" class="img-responsive" />
                  </figure>
                  <p class="menu-2-p" style="margin-top: 0px">
                    {{ prod.description }}
                  </p>
                </div>
              </div>
              <div class="item col-md-6 col-sm-6 col-xs-12 shop text-center right_section">
                <div class="optional_input_cnt">
                  <h2 class="menu-2-b">{{ prod.name }} <span class="added_tag">{{ $lang._('Added') }}</span></h2>

                  <!-- bread type -->
                  <div v-if="prod.bread && prod.bread.length > 0" class="form-group">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-select" @click="toggleDropdown('bread')">
                        {{ selectedBreadType ? selectedBreadType.name : 'Bread type' }}
                        <i class="fa fa-angle-down fa-indicator"></i>
                      </div>
                      <div class="custom-dropdown-content" :class="{ 'expanded': isDropdownExpanded.bread }">
                        <button v-for="obj in prod.bread" :key="obj.id" type="button" @click="changeBread(obj)">
                          <img :src="obj.image" :alt="obj.name" />
                          <span class="clikableSpan">{{ obj.name }}</span>
                          <span class="clikableSpan label add_icon">+{{ obj.price }}€</span>

                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Ingredients -->
                  <div v-if="prod.ingredient && prod.ingredient.length > 0" class="form-group">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-select" @click="toggleDropdown('ingredient')">
                        Ingredients (Max: {{ prod.max_ingredients }})
                        <i class="fa fa-angle-down fa-indicator"></i>
                      </div>
                      <div class="custom-dropdown-content" :class="{ 'expanded': isDropdownExpanded.ingredient }">
                        <button v-for="ing in prod.ingredient" :key="ing.id" type="button">
                          <img :src="ing.image" :alt="ing.name" />
                          <span>{{ ing.name }}</span>

                          <span v-if="getIngredietQty(ing) > 0">[{{ getIngredietQty(ing) }}]</span>
                          <div>
                            <span @click="updateIngredient(ing, 'minus')">
                              <i class="fa fa-minus-square add_icon minus_icon_button"
                                :class="{ active: getIngredietQty(ing) == 1 }" aria-hidden="true"></i>
                            </span>

                            <span class="label add_icon">{{ ing.price }}€</span>
                            <span @click="updateIngredient(ing, 'add')">
                              <i class="fa fa-plus-square add_icon add_icon_button"
                                :class="{ active: getIngredietQty(ing) == 0 }" aria-hidden="true"></i>
                            </span>
                          </div>


                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- additional ingredients (without sauce) -->
                  <div class="form-group" v-if="additionalWithoutSauce && additionalWithoutSauce.length > 0">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-select" @click="toggleDropdown('additional')">
                        Additional Ingredients
                        <i class="fa fa-angle-down fa-indicator"></i>
                      </div>
                      <div class="custom-dropdown-content" :class="{ 'expanded': isDropdownExpanded.additional }">
                        <button v-for="additionalIng in additionalWithoutSauce" :key="additionalIng.id" type="button">
                          <img :src="additionalIng.image" :alt="additionalIng.name" />
                          <span>{{ additionalIng.name }}</span>
                          <span v-if="getAdditionalQty(additionalIng) > 0">
                            [{{ getAdditionalQty(additionalIng) }}]
                          </span>
                          <div>
                            <span @click="updateAdditional(additionalIng, 'minus')">
                              <i class="fa fa-minus-square add_icon minus_icon_button"
                                :class="{ active: getAdditionalQty(additionalIng) > 0 }" aria-hidden="true"></i>
                            </span>
                            <span class="label add_icon">{{ additionalIng.price }}€</span>
                            <span @click="updateAdditional(additionalIng, 'add')">
                              <i class="fa fa-plus-square add_icon" aria-hidden="true"></i>
                            </span>
                          </div>


                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- additional ingredients (only tagged as sauce ) -->
                  <div class="form-group" v-if="additionalWithSauce && additionalWithSauce.length > 0">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-select" @click="toggleDropdown('sauce')">
                        Sauce
                        <i class="fa fa-angle-down fa-indicator"></i>
                      </div>
                      <div class="custom-dropdown-content" :class="{ 'expanded': isDropdownExpanded.sauce }">
                        <button v-for="additionalIng in additionalWithSauce" :key="additionalIng.id" type="button">
                          <img :src="additionalIng.image" :alt="additionalIng.name" />
                          <span>{{ additionalIng.name }}</span>
                          <span v-if="getAdditionalQty(additionalIng) > 0">
                            [{{ getAdditionalQty(additionalIng) }}]
                          </span>
                          <div>
                            <span @click="updateAdditional(additionalIng, 'minus')">
                              <i class="fa fa-minus-square add_icon minus_icon_button"
                                :class="{ active: getAdditionalQty(additionalIng) > 0 }" aria-hidden="true"></i>
                            </span>
                            <span class="label add_icon">{{ additionalIng.price }}€</span>
                            <span @click="updateAdditional(additionalIng, 'add')">
                              <i class="fa fa-plus-square add_icon" aria-hidden="true"></i>
                            </span>
                          </div>


                        </button>
                      </div>
                    </div>
                  </div>

                  <textarea id="messageTextarea" v-model="commentValue" placeholder="Add your comment"></textarea>
                </div>

                <div class="form-group remember">
                  <input type="checkbox" v-model="save_customization" id="save_customization" name="save_customization"
                    value="save_customization" />
                  <label for="save_customization">
                    {{ $lang._('save_order') }}</label><br />
                </div>
                <!-- <div class="price">
                  <span class="menu-2-span content-subhead">{{ prod.price }}€</span>
                </div> -->
                <a class="orderButton" data-dismiss="modal" @click="navigateTo('client_cart_page')">Checkout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect, watch } from "vue";
export default {
  name: "ProductModal",
  components: {},
  props: {
    prod: {},
    addedIndex: {},
  },
  computed: {
    save_customization: {
      get() {
        const cart = this.$store.state.cart;
        let dateIndex = this.addedIndex.dateIndex;
        let itemIndex = this.addedIndex.itemIndex;
        if (cart[dateIndex] && cart[dateIndex].items[itemIndex]) {
          return cart[dateIndex].items[itemIndex].save_customization;
        } else {
          return '';
        }
      },
      set(value) {
        let dateIndex = this.addedIndex.dateIndex;
        let itemIndex = this.addedIndex.itemIndex;
        this.$store.commit('setOrderLineSaveCustomization', { dateIndex: dateIndex, itemIndex: itemIndex, value });
      }
    },
    commentValue: {
      get() {
        const cart = this.$store.state.cart;
        let dateIndex = this.addedIndex.dateIndex;
        let itemIndex = this.addedIndex.itemIndex;
        if (cart[dateIndex] && cart[dateIndex].items[itemIndex]) {
          return cart[dateIndex].items[itemIndex].comment;
        } else {
          return '';
        }
      },
      set(value) {
        let dateIndex = this.addedIndex.dateIndex;
        let itemIndex = this.addedIndex.itemIndex;
        this.$store.commit('setOrderLineComment', { dateIndex: dateIndex, itemIndex: itemIndex, value });
      }
    },
    additionalWithSauce() {
      if (!(this.prod.additional && this.prod.additional.length > 0)) {
        return []
      }
      let list = []
      list = this.prod.additional.filter(obj => obj.tag == this.sauceTag)
      return list
    },
    additionalWithoutSauce() {
      if (!(this.prod.additional && this.prod.additional.length > 0)) {
        return []
      }
      let list = []
      list = this.prod.additional.filter(obj => obj.tag != this.sauceTag)
      return list
    }

  },
  data() {
    return {
      selectedBreadType: null,
      sauceTag: process.env.VUE_APP_souce_tag,
      isDropdownExpanded: {
        bread: false,
        ingredient: false,
        additional: false,
        sauce: false
      }
    };
  },
  methods: {
    updateAdditional(obj, type) {
      this.$store.dispatch("addAdditionalIngredientByIndex", {
        orderLineIndex: this.addedIndex,
        Addedingredient: obj,
        operation: type
      })
    },

    updateIngredient(obj, type) {
      this.$store.dispatch("changeIngredientByIndex", {
        orderLineIndex: this.addedIndex,
        Addedingredient: obj,
        operation: type
      }).then(result => {
        console.log(result);
        if (result != '200')
          this.raiseAlert('warning', result)
      })
    },

    getIngredietQty(obj) {
      return this.$store.getters.ingredientQuantity({
        orderLineIndex: this.addedIndex,
        ingredient: obj,
      })
    },

    getAdditionalQty(obj) {
      return this.$store.getters.additionalQuantity({
        orderLineIndex: this.addedIndex,
        ingredient: obj,
      })
    },
    toggleDropdown(dropdown) {
      // Close all dropdowns
      for (let key in this.isDropdownExpanded) {
        if (key != dropdown)
          this.isDropdownExpanded[key] = false;
      }
      this.isDropdownExpanded[dropdown] = !this.isDropdownExpanded[dropdown];
    },
    changeBread(obj) {
      console.log("Change bread type to this:");
      this.$store.dispatch("changeBreadByIndex", {
        orderLineIndex: this.addedIndex,
        SelectedBreadType: obj,
      });
      this.selectedBreadType = obj;
    },

  },

  watch: {
    prod: {
      handler(newVal) {
        if (newVal && newVal.bread && newVal.bread.length > 0) {
          if (!this.selectedBreadType) {
            console.log('The selected bread type is null, choose default')
            this.selectedBreadType = newVal.bread.find(bread => bread.is_default);
          }

        }
      },
      // deep: true,
      immediate: true,
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.modal-title {
  color: black;
}

span.clikableSpan {
  cursor: pointer;
}
</style>