<template>
  <div>
    <!-- counter -->
    <section class="main">
      <div id="ri-grid" class="ri-grid ri-grid-size-2">
        <ul>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-9.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-10.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-9.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-10.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-9.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-10.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/1.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/2.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/4.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/5.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/6.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/7.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/8.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-3.jpg"
              />
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="/theme_1/theme_restora/img/image-grid/grid-4.jpg"
              />
            </a>
          </li>
        </ul>
      </div>
    </section>
    <div class="counter-sec btn-bg">
      <div class="btn-bg"></div>
      <p class="counter-num" data-count="2581851">2581000</p>
      <h3>Food lover And COUNTING!</h3>
    </div>
  </div>
</template>


<script>

export default ({
  name: 'ImageComponent',
  mounted(){
    $('#ri-grid').gridrotator({
			  rows: 3,
			  columns: 15,
			  animType: 'fadeInOut',
			  animSpeed: 1000,
			  interval: 200,
			  step: 1,
			  w320: {
				rows: 3,
				columns: 4
			  },
			  w240: {
				rows: 3,
				columns: 4
			  }
			});
  }
})
</script>
