<template>
  <div>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" />
    <div id="wrapper">
      <HeaderComponent />
      <page_header title="Company demo" subtitle="DELICIOUS FOOD. TO FIT YOUR LIFESTYLE." :short_header="true"
        pageName="Comapny demo" />
      <div class="container padd-20">
        <div class="col-md-12 text-center">
          <h2 class="month">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            <b style="font-family: Pacifico">{{ currentMonth }}</b>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </h2>
        </div>
        <div class="col-md-12 days_cnt horizontal-scrollable ">
          <div class="row pricing dates_list">
            <div v-for="(date, index) in $store.getters.GetClientDates" :key="index" class="single_day">
              <div class="plans-body text-center" @click="setActiveDate(date.date)">
                <div class="plan-icon">
                  <i class="fa fa-calendar-plus-o"></i>
                </div>
                <h2>{{ $lang._(date.day_name) }}</h2>
                <a :class="[
                  'plan-btn',
                  'btn-bg',
                  { 'btn-disactive': date.date !== active_date },
                ]">{{ date.day_number }}</a>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <!-- client shop container -->
      <section class="client_shop_cnt  wow animate fadeInUp">
        <div class="container-fluid padd-bottom">
          <!-- categories -->
          <div class="row">
            <div class="col-sm-12 categories_column">
              <div class="col-sm-12 text-center">
                <div id="projects-filter">
                  <div class="gallery-head">
                    <a v-for="cat in categories" :key="cat.id" data-filter="*"
                      :class="{ active: cat.id == active_category }" @click="filterProductsByCategory(cat.id)">
                      {{ cat.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Filters - products - cart -->
          <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-12 tag_filters">
              <div>
                <ul v-if="active_category == SandwichCatID">
                  <li v-for="tag in $store.getters.getTags" :key="tag.id" class="custom-control custom-checkbox"
                    :class="{ 'mobile_tag_checked': isSelected(tag.id) }">
                    <input type="checkbox" class="custom-control-input" :id="`customCheck${tag.id}`"
                      @change="handleTagChange(tag, $event)" :checked="isSelected(tag.id)" />

                    <label class="custom-control-label" :for="`customCheck${tag.id}`">
                      <span class="hidden-md hidden-lg">#</span>{{ tag.name }}
                    </label>
                  </li>
                </ul>
                <ul v-else>
                  <li class="custom-control custom-checkbox mobile_tag_checked">
                    <label class="custom-control-label" for="all_prod_tag">Showing all</label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="products all_products" :class="productShopCntClass">
              <Product_client_shop :products="products" @add_to_cart_show_popup="add_2_cart_n_show_popup" />
            </div>
            <!-- we dont show this section in mobile, as the user will use float button to go to cart -->
            <div v-if="!$store.getters.isCartEmpty" class="col-md-3 col-sm-12 hidden-xs cart">
              <Cart ref="cartComponent" />
            </div>
          </div>
        </div>
        <button v-if="!$store.getters.isCartEmpty" class="cart-button" @click="cartPlaceOrder">Cart</button>
      </section>

      <!-- active_orderLine to be used later -->
      <ProductModal v-if="active_prod" :prod="active_prod" :addedIndex="activeOrderLineIndex" />

      <FooterComponent />
    </div>
  </div>
</template>

<script>
import { watchEffect, watch } from "vue";
// view
import HeaderComponent from "@/views/components/Header";
import Product_client_shop from "@/views/components/Product_client_shop";
import ImageAndCounter from "@/views/home/ImageAndCounter";
import FooterComponent from "@/views/components/Footer";
import page_header from "@/views/components/page_header";
import ProductModal from "./ProductModal";
import Cart from "./Cart";
// Composables
import GetProducts from "@/composables/GetProducts";
import GetData from "@/composables/GetData";
import GetTags from "@/composables/Tags";
import GetClientDates from "@/composables/GetClientDates";
export default {
  name: "ClientShop",
  components: {
    HeaderComponent,
    ImageAndCounter,
    page_header,
    Product_client_shop,
    FooterComponent,
    ProductModal,
    Cart,
  },

  data() {
    return {
      products: [],
      originalProducts: [],
      data_error: null,
      categories: [],
      categories_error: null,
      active_category: null,
      activeTag: null,
      active_date: null,
      activeOrderLineIndex: null,
      active_prod: null,
      SandwichCatID: process.env.VUE_APP_sandwich_category_id
    };
  },
  mounted() {
    if (this.$store.getters.authUser) {
      this.loadCategories();
      this.filterProductsByCategory(3);
      this.loadTags();
      this.loadDates();
    }else{
      this.navigateTo('user_page');
    }


  },
  methods: {
    loadproducts(catId) {
      let { loadedData, data_error, loadProductData } = GetProducts();
      watch([loadedData, data_error], () => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        } else if (!this.isRefEmpty(loadedData)) {
          this.products = loadedData.value;
          this.originalProducts = loadedData.value;
          this.filterProductsByCategory(this.active_category)
        } else {
          // no error but the result is empty, assign empty array
          this.products = [];
          this.originalProducts = [];
        }
      });
      loadProductData(catId);

    },

    loadCategories() {
      let { loadedData, data_error, loadTableData } = GetData();
      watch([loadedData, data_error], () => {
        if (!this.isRefEmpty(data_error)) {
          this.categories_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          this.categories = loadedData.value;
          let categIds = this.categories.map(cat => cat.id);
          this.active_category = categIds[0]

          this.loadproducts(categIds.join(','))
        }

      });
      loadTableData("categories", 'show_all', '1');
    },

    loadTags() {
      const { loadedData, data_error, loadTagsData } = GetTags();
      watchEffect(() => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        }
      });
      loadTagsData(0, 1);
    },

    loadDates() {
      const { loadedData, data_error, loadData } = GetClientDates();
      watchEffect(() => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          if (!this.active_date) {
            this.active_date = loadedData.value[0].date;
          }
        }
      });
      loadData();
    },

    filterProductsByCategory(categoryId) {
      this.active_category = categoryId;
      // When the category is changed, we reset the active tag, as none sandwich categories don't have tags
      this.activeTag = null;
      if (!categoryId) {
        return [];
      }
      this.products = this.originalProducts.filter((product) => {
        return product.category === categoryId;
      });

    },

    handleTagChange(tag, event) {
      if (event.target.checked) {
        this.filterProductsByTag(tag.id);
      } else {
        this.filterProductsByTag();
      }
    },

    isSelected(tagId) {
      return this.activeTag === tagId;
    },

    filterProductsByTag(activeTagId) {
      this.activeTag = activeTagId;
      if (!activeTagId) {
        this.filterProductsByCategory(this.active_category);
        return;
      }
      this.products = this.originalProducts.filter((product) => {
        return product.tag.some((tag) => tag.id === activeTagId) && (!this.active_category || product.category === this.active_category);
      });
    },

    setActiveDate(date) {
      this.active_date = date; // Assuming active_date is a data property in your component
    },
    add_2_cart_n_show_popup(prod) {
      this.$store
        .dispatch("addToCartAndReturnIndex", {
          date: this.active_date,
          product: prod,
        })
        .then((addedIndex) => {
          this.active_prod = prod;
          this.activeOrderLineIndex = addedIndex;
        });
    },
    cartPlaceOrder() {
      this.active_prod = null,
        this.navigateTo('client_cart_page');
    }
  },
  computed: {
    currentMonth() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      if (this.active_date) {
        const dateParts = this.active_date.split("-"); // Split the active_date string into parts
        const monthIndex = parseInt(dateParts[1]) - 1; // Get the month index (0-indexed)
        return this.$lang._(months[monthIndex]);
      } else {
        return "";
      }
    },
    productShopCntClass() {
      return [
        'col-sm-8 col-xs-12',
        {
          'col-md-10': this.$store.getters.isCartEmpty,
          'col-md-7': !this.$store.getters.isCartEmpty
        }
      ];
    }

  },
};
</script>

<style>
.cart-button {
  position: fixed;
  bottom: 15%;
  left: 5px;
  background-color: var(--dark-orange);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.all_products {
  margin: auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 20px 40px;
}

/* Media query to display the button only on small screens */
@media (min-width: 768px) {
  .cart-button {
    display: none;
  }
}
</style>