import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetSuggestedProducts = () => {

  const loadedData = ref([])
  const data_error = ref(null)

  const loadSuggestionData = async (tags, limitPrice) => {
    try {
      // api/special-products?tag_ids=1&limit_price=30
      console.log(tags)
      let apiUrl = `${baseUrl}products/api/special-products/`
      let params = {};
      const tagIds = tags.map(tag => tag.id).join(',');
      console.log(tagIds)
      if (tagIds) {
        params['tag_ids']  = tagIds
      }
      
      if (limitPrice) {
        params['limit_price']  = limitPrice
      }
      let requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params), 
      };
      let data = await fetch(apiUrl,requestOptions)
      if (!data.ok) {
        throw Error('no available product')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedData.value = res.data
        }else{
          console.log('unable to load products')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loadedData, data_error, loadSuggestionData }
}

export default GetSuggestedProducts