<template lang="">
      <header id="header" class="header">
        <div class="menu">
          <nav id="menu-1" class="mega-menu ">
            <section class="menu-list-items">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <ul class="menu-logo">
                      <li class="head-info">
                        <router-link to="/" class="">
                          <img id="logo_img"  src="@assets/img/logo.png" alt="logo">
                          </router-link>
                          <div @click="toggle" :class="{ active: menuActive }" class="menu-mobile-collapse-trigger"><span></span></div>
                      </li>
                    </ul>
                    <ul class="menu-links" :style="{ display: menuActive ? 'block' : 'none', maxHeight: '400px', overflow: 'auto' }">                  
                      <li>
                        <router-link to="/" class="menu-style menu-li-a" 
                        :class="{ active: $route.path === '/', }">  {{ $lang._("home") }} </router-link>
                      </li>
                    
                      <li>
                        <router-link to="/contact_us" class="menu-style menu-li-a"
                        :class="{ active: $route.path === '/contact_us' }" >  {{ $lang._("contact") }} </router-link>
                      </li>
                      <li v-if="$store.getters.authUser">
                       <router-link to="/shop_client" class="menu-style menu-li-a"
                       :class="{active: $route.path.startsWith('/shop_')}">  Demo Shop </router-link>
                      </li>
                      <li>
                       <router-link to="/menu" class="menu-style menu-li-a"
                       :class="{active: $route.path.startsWith('/menu')}">  Menu </router-link>
                      </li>
                    
                   
                      <li v-if="!$route.path.startsWith('/catermate')">
                       <router-link to="/catermate" class="menu-style menu-li-a"> CaterMate </router-link>
                      </li>
                      <li v-else>
                        <router-link to="/catermate" class="menu-book-table-btn btn-bg special_menu_book">
                           CaterMate 
                           </router-link>
                      </li>
                       <!-- Language Dropdown -->
                       <li class="dropdown" v-if="dev_show_menu">
                          <a  class="menu-style menu-li-a">{{$lang.getLang()}}</a>
                          <div class="dropdown-content">
                            <a  @click="$lang.setLanguage('en')">En</a>
                            <a  @click="$lang.setLanguage('fr')">FR</a>
                            <a >NL</a>
                          </div>
                        </li>
                        <li class="dropdown" v-if="$store.getters.authUser">
                          <a href="#" class="menu-style menu-li-a">Hello {{$store.getters.authUser.name}}</a>
                          <div class="dropdown-content">
                            <a  @click="logout()">Logout</a>
                          </div>
                        </li>
                        <li v-else>
                          <router-link to="/user" class="menu-style menu-li-a"
                          :class="{active: $route.path === ('/user')}">  {{ $lang._("login") }} </router-link>
                        </li>
                       
                        <!-- End Language Dropdown -->
                     
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </nav>
        </div>
   
      </header>
</template>


<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      menuActive: false,
      dev_show_menu:false
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout');
      this.navigateTo('home_page');
    },
    toggle() {
      this.menuActive = !this.menuActive;
    }
  },
  mounted() {
    this.$store.dispatch('fetchUserData');
  }
};

</script>



<style scoped>
.mega-menu .menu-links>li>a.special_menu_book {
  padding: 8px 20px;
  line-height: 30px;
  margin-top: 10px;
  color: #fff;
  margin-bottom: 8px;
}

.mega-menu .menu-links>li>a {
  text-decoration: none;
}

/* Dropdown button */

/* Dropdown container */

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* .mega-menu .menu-logo li img,
.header-5 .logo img {
  height: 70px;
  margin-top: -15px;
  
} */
</style>