<template>
  <div id="wrapper">

    <section>
      <HeaderComponent />
      <page_header title="Delicious Menu" subtitle="FRESH, FAST, TASTY" pageName="MENU" />
      <!--CONTENT-SLIDER-->
      <div class="container-fluid padd-70">
        <div class="container">
          <div class="col-md-12 text-center slider-2">
            <div class="restora-icon"><i class="flaticon-dinner"></i></div>
            <h3 style="font-weight: bold; font-size: 22px">MENU SPECIALS</h3>
            <h2 class="content-subhead">Chief Recommended</h2>
            <!-- <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum porttitor egestas orci.
            </p> -->
            <span class="contact-underline"></span>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12 text-center index-tab">
            <ul v-if="loadedMenuCategories && loadedMenuCategories.length > 0" class="nav nav-tabs" role="tablist"
              style="margin: 40px 0">
              <li role="presentation" v-for="(category, index) in loadedMenuCategories" :key="index"
                :class="{ 'active': index === 0 }">
                <a class="sld-btn-bor" :href="'#' + category.name" aria-controls="messages" role="tab" data-toggle="tab"
                  style="color: #191919">{{ category.name }}</a>
              </li>
            </ul>
            <div v-if="loadedMenuCategories && loadedMenuCategories.length > 0" class="tab-content">
              <div role="tabpanel" v-for="(category, index) in loadedMenuCategories" :key="index"
                :class="{ 'tab-pane fade in active': index === 0, 'tab-pane fade': index !== 0 }" :id="category.name">
                <div class="col-sm-6 col-md-6 col-lg-4">
                  <div v-if="category.id" class="figure effect-steve">
                    <img :src="getCategoryImagePath(category)" class="img-responsive" />
                    <!-- <div class="figcaption">
                      <h2>{{ category.name }}</h2>
                    </div> -->
                  </div>
                </div>
                <!--menu list-->
                <div class="col-sm-6 col-md-6 col-lg-8">
                  <div class="menu-list-2" v-for="(item, itemIndex) in category.menu_items" :key="itemIndex">
                    <span style="text-align: left;margin-bottom: 5px;"> {{ item.name }}</span>
                    <h4>{{ item.description }}</h4>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="container-fluid padd-70">
        <div class="contaier">
          <div class="col-md-12 text-center slider-2">
            <MenuTagsTable />
          </div>
        </div>
      </div>

      <!--CAPTION-->
      <div class="container-fluid menu-caption-bg">
        <div class="menu-caption-bg-black padd-70">
          <div class="container">
            <div class="col-md-12 text-center">
              <h2>Test our delicious Food</h2>
              <router-link to="/contact_us">
                Contact us
              </router-link>
              <!-- <a href="#"></a> -->
              <!-- <p> (+01) 987 654 3210</p> -->
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </section>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/views/components/Header";
import ImageAndCounter from "@/views/home/ImageAndCounter";
import FooterComponent from "@/views/components/Footer";
import page_header from "@/views/components/page_header";
import MenuTagsTable from "@/views/components/MenuTagsTable";
import GetMenu from "@/composables/GetMenu"
export default {
  name: "MenuView",
  components: {
    HeaderComponent,
    ImageAndCounter,
    page_header,
    FooterComponent, MenuTagsTable
  },
  data() {
    return {
      data_error: null,
      loadedMenuCategories: null,
    }
  },
  created() {
    this.loadMenuData();
  },
  methods: {
    async loadMenuData() {
      const menu = GetMenu();
      try {
        await menu.loadMenuCategories();
        this.loadedMenuCategories = menu.loadedMenuCategories.value;
      } catch (error) {
        this.data_error = error.message;
      }
    },
    getCategoryImagePath(category) {
      if (category && category.id)
        return require(`@/assets/img/menu_categories/${category.id}.jpeg`);
      return '';
    }
  }

};
</script>