import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetProducts = () => {

  const loadedData = ref([])
  const data_error = ref(null)

  const loadProductData = async (category_ids=null,limit=0) => {
    try {
      let apiUrl = `${baseUrl}products/api/products/`;
      let params = {'limit':limit};
      if(category_ids!=null){
        params['category_ids']  = category_ids
      }
      let requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params), 
      };
      let data = await fetch(apiUrl,requestOptions)
      if (!data.ok) {
        throw Error('no available home products')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedData.value = res.data
          
        }else{
          console.log('unable to load home products')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loadedData, data_error, loadProductData }
}

export default GetProducts