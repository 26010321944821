<template>
  <div>
    <div class="container restora main_questionnaire">
      <!-- Timeline steps -->
      <div class="timeline">
        <div
          v-for="(step, index) in 6"
          :key="step"
          @click="setCurrentStep(step)"
          :class="{
            active: step === currentStep,
            completed: step < currentStep || currentStep == 6,
          }"
          class="step"
        >
          <span class="circle">{{ step }}</span>
          <!-- Conditionally render the line if the step is not the last one -->
          <div v-if="index < 4" class="line"></div>
        </div>
      </div>
      <!-- End of timeline steps -->
      <div class="row g-2 g-lg-3">
        <div class="col-lg-12 text-center">
          <h2 class="sloganTitle">
            {{ $lang._("CaterMate") }}:
            {{ $lang._("Your Event Personal Helper") }}
          </h2>
        </div>
        <!-- guest count -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 question_step"
          v-if="currentStep === 1"
        >
          <p class="question_label">
            <label for="gestCount">Guest count:</label> How many guests are you
            expecting at your event?
          </p>
          <div class="form-floating mb-3">
            <input
              class="form-control"
              type="number"
              id="gestCount"
              placeholder="75"
              v-model="eventDetails.guestCount"
              @keyup.enter="handleEnterKey()"
            />

            <a
              :class="{ disabled: !hasGuestCount }"
              @click="hasGuestCount && nextStep()"
              class="btn-bor"
            >
              Next</a
            >
          </div>
        </div>

        <!-- Step 2: Budget Allocation -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 question_step"
          v-if="currentStep === 2"
        >
          <p class="question_label">
            <label for="budgetPerPerson">Budget per Person:</label> For
            {{ eventDetails.guestCount }} guests, what is your budget per person
            ? (e.g. €30)
          </p>
          <div class="form-floating mb-3">
            <input
              class="form-control"
              type="number"
              id="budgetPerPerson"
              v-model="eventDetails.budgetPerPerson"
              @keyup.enter="handleEnterKey()"
            />
            <a
              :class="{ disabled: !hasBudget }"
              @click="hasGuestCount && nextStep()"
              class="btn-bor"
            >
              Next</a
            >
          </div>
        </div>

        <!-- Step 3: Preferred Cuisine tags -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 question_step"
          v-if="currentStep === 3"
        >
          <p class="question_label">
            For {{ eventDetails.guestCount }} guests with a budget of €{{
              eventDetails.budgetPerPerson
            }}
            per person, select up to three cuisine categories you'd like for
            your event:
          </p>

          <div
            v-for="tag in $store.getters.getQuestionnaireTags"
            :key="tag.id"
            class="form-check question_form_check"
          >
            <input
              type="checkbox"
              :id="'cuisine' + tag.id"
              :value="tag"
              v-model="eventDetails.tags"
              class="form-check-input"
            />
            <label :for="'cuisine' + tag.id" class="form-check-label">{{
              tag.name
            }}</label>
          </div>

          <a
            :class="{ disabled: !hasSelectedCuisine }"
            @click="hasSelectedCuisine && nextStep()"
            class="btn-bor"
          >
            Next</a
          >
        </div>

        <!-- Step 4: Event Atmosphere -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 question_step"
          v-if="currentStep === 4"
        >
          <p class="question_label">
            What type of atmosphere are you aiming for?
          </p>

          <div
            v-for="eventType in $store.getters.getEventTypes"
            :key="eventType.id"
            class="form-check question_form_check"
          >
            <input
              type="radio"
              :id="'atmosphere' + eventType.id"
              :value="eventType"
              v-model="eventDetails.eventAtmosphere"
              class="form-check-input"
            />
            <label
              :for="'atmosphere' + eventType.id"
              class="form-check-label"
              >{{ eventType.name }}</label
            >
          </div>

          <a
            :class="{ disabled: !hasSelectedAtmosphere }"
            @click="hasSelectedAtmosphere && nextStep()"
            class="btn-bor"
          >
            Next</a
          >
        </div>

        <!-- Step 5: Special Requests -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 special_requests question_step"
          v-if="currentStep === 5"
        >
          <p class="question_label">Do you have any special requests?</p>
          <!-- vegan -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="vegan"
              v-model="eventDetails.specialRequests.vegan"
              class="form-check-input"
            />
            <label for="vegan" class="form-check-label">Vegan</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.vegan">
              <p class="question_label mt-3">
                Number of Guests with Vegan Requirements
              </p>
              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="eventDetails.specialRequests.veganCount"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
          <!-- halal -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="halal"
              v-model="eventDetails.specialRequests.halal"
              class="form-check-input"
            />
            <label for="halal" class="form-check-label">Halal</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.halal">
              <p class="question_label mt-3">
                Number of Guests with Halal Requirements
              </p>
              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="eventDetails.specialRequests.halalCount"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
          <!-- gluten_free -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="gluten_free"
              v-model="eventDetails.specialRequests.gluten_free"
              class="form-check-input"
            />
            <label for="gluten_free" class="form-check-label">Gluten free</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.gluten_free">
              <p class="question_label mt-3">
                Number of Guests with Gluten Free Requirements
              </p>
              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="eventDetails.specialRequests.gluten_free_count"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
          <!-- kosher -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="kosher"
              v-model="eventDetails.specialRequests.kosher"
              class="form-check-input"
            />
            <label for="kosher" class="form-check-label">Kosher</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.kosher">
              <p class="question_label mt-3">
                Number of Guests with Kosher Requirements
              </p>
              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="eventDetails.specialRequests.kosher_count"
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <!-- dairy_free -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="dairy_free"
              v-model="eventDetails.specialRequests.dairy_free"
              class="form-check-input"
            />
            <label for="dairy_free" class="form-check-label">Dairy Free</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.dairy_free">
              <p class="question_label mt-3">
                Number of Guests with Dairy Free Requirements
              </p>
              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="eventDetails.specialRequests.dairy_free_count"
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <!-- alergies -->
          <div class="form-check question_form_check">
            <input
              type="checkbox"
              id="allergies"
              v-model="eventDetails.specialRequests.allergies"
              class="form-check-input"
            />
            <label for="allergies" class="form-check-label">Allergies</label>
            <div class="special_answer_div" v-if="eventDetails.specialRequests.allergies">
              <p class="question_label mt-3">
                Write details & number of Guests with Allergies
              </p>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  v-model="eventDetails.specialRequests.allergies_details"
                  placeholder="E.g. 2 guests with peanut allergy"
                />
              </div>
            </div>
          </div>
          <a @click="nextStep()" class="btn-bor">Next</a>
        </div>

        <!-- Step 6: Summary of suggestions -->
        <div
          class="col-lg-6 col-lg-offset-3 mt-5 mb-5 question_step"
          v-if="currentStep === 6"
        >
          <p class="question_label">Here's a summary of your preferences:</p>
          <ul class="question_summary">
            <li>
              <i
                class="fa fa-angle-right content-subhead"
                aria-hidden="true"
              ></i>
              Number of Guests: {{ eventDetails.guestCount }}
            </li>
            <li>
              <i
                class="fa fa-angle-right content-subhead"
                aria-hidden="true"
              ></i>
              Budget per Person: €{{ eventDetails.budgetPerPerson }}
            </li>
            <li>
              <i
                class="fa fa-angle-right content-subhead"
                aria-hidden="true"
              ></i>
              Preferred Cuisines:
              <span
                v-for="tag in eventDetails.tags"
                :key="tag.id"
                class="badge bg-secondary question_badge"
                >{{ tag.name }}</span
              >
            </li>
            <li>
              <i
                class="fa fa-angle-right content-subhead"
                aria-hidden="true"
              ></i>
              Preferred Atmospheres:
              <span class="badge bg-secondary question_badge">{{
                eventDetails.eventAtmosphere.name
              }}</span>
            </li>
          </ul>
          <p class="question_label">
            The average total cost for the event is: €{{ averageTotal }}
          </p>
          <p class="question_label">
            Does everything look good? If yes, click "Confirm" to proceed.
          </p>

          <a @click="confirmEvent()" class="btn-bor"> See results</a>
        </div>

        <div v-if="data_error" class="col-lg-6 col-lg-offset-3 mt-5 mb-5">
          <p class="alert alert-danger error_msg">{{ data_error }}</p>
        </div>
      </div>
    </div>
    <div
      class="container-fluid home-2-bg"
      v-if="
        product_suggestions.main_meals &&
        product_suggestions.main_meals.length > 0
      "
    >
      <div class="container padd-70">
        <div class="text-center col-md-12 col-sm-12 col-xs-12">
          <h3 style="font-weight: 700; font-size: 22px; color: #fff">
            Here are some possible combinations per person
          </h3>
          <div class="text-center col-md-3 col-sm-12 col-xs-12">
            <a
              @click="show_section('main')"
              :class="{ section_active: suggestion_section == 'main' }"
              class="btn-bg menu-all-btn"
              >Main</a
            >
          </div>
          <div class="text-center col-md-3 col-sm-12 col-xs-12">
            <a
              @click="show_section('appetizers')"
              :class="{ section_active: suggestion_section == 'appetizers' }"
              class="btn-bg menu-all-btn"
              >Appetizers</a
            >
          </div>
          <div class="text-center col-md-3 col-sm-12 col-xs-12">
            <a
              @click="show_section('drinks')"
              :class="{ section_active: suggestion_section == 'drink' }"
              class="btn-bg menu-all-btn"
              >Drinks</a
            >
          </div>
          <div class="text-center col-md-3 col-sm-12 col-xs-12">
            <a
              @click="show_section('combination')"
              :class="{ section_active: suggestion_section == 'combination' }"
              class="btn-bg menu-all-btn"
              >Combination</a
            >
          </div>
          <span class="contact-underline"> </span>
        </div>
        <div class="clearfix"></div>
        <div class="content">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            v-if="suggestion_section == 'main'"
          >
            <Product_cell
              v-for="prod in product_suggestions.main_meals"
              :key="prod.id"
              :prod="prod"
            />
          </div>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            v-if="suggestion_section == 'appetizers'"
          >
            <Product_cell
              v-for="prod in product_suggestions.Appetizers"
              :key="prod.id"
              :prod="prod"
            />
          </div>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            v-if="suggestion_section == 'drinks'"
          >
            <Product_cell
              v-for="prod in product_suggestions.Drinks"
              :key="prod.id"
              :prod="prod"
            />
          </div>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            v-if="suggestion_section == 'combination'"
          >
            <div
              class="col-md-12 col-sm-12 col-xs-12"
              v-for="(combination, index) in product_suggestions.combination"
              :key="index"
            >
              <Product_cell
                v-for="prod in combination"
                :key="prod.id"
                :prod="prod"
              />
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, watchEffect } from "vue";
import GetSuggestedProducts from "@/composables/GetSuggestedProducts";
import Product_cell from "@/views/components/Product_cell";
import GetTags from "@/composables/Tags";
import GetEventTypes from "@/composables/EventTypes";
export default {
  components: {
    Product_cell,
  },
  data() {
    return {
      currentStep: 1,
      data_error: null,
      suggestion_section: "main",
      product_suggestions: [],
      eventDetails: {
        description: "",
        guestCount: null,
        budgetPerPerson: null,
        tags: [],
        eventAtmosphere: null,
        specialRequests: {
          vegan: false,
          veganCount: 0,
          halal: false,
          halalCount: 0,
          gluten_free: false,
          gluten_free_count: 0,
          kosher: false,
          kosher_count: 0,
          dairy_free: false,
          dairy_free_count: 0,
          allergies: false,
          allergies_details:''
        },
      },
    };
  },
  methods: {
    nextStep() {
      this.currentStep += 1;
    },
    setCurrentStep(step) {
      this.currentStep = step;
    },
    show_section(name) {
      this.suggestion_section = name;
    },
    confirmEvent() {
      // Add logic to handle the form submission (e.g., send data to the server)
      console.log(this.eventDetails);
      this.loadproducts(
        this.eventDetails.tags,
        this.eventDetails.budgetPerPerson
      );
    },

    loadproducts(tags, limitPrice) {
      const { loadedData, data_error, loadSuggestionData } = GetSuggestedProducts();
      watch([loadedData, data_error], () => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          console.log("product suggestion loaded");
          this.product_suggestions = loadedData.value;
          console.log(this.product_suggestions);
        }
      });
      loadSuggestionData(tags, limitPrice);
    },

    loadTags() {
      const { loadedData, data_error, loadTagsData } = GetTags();
      watchEffect(() => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          console.log("tags loaded in questionnarie");
          console.log(loadedData)
        }
      });
      loadTagsData(1);
    },

    loadEventTypes() {
      const { loadedData, data_error, loadData } = GetEventTypes();
      watchEffect(() => {
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
        }
        if (!this.isRefEmpty(loadedData)) {
          console.log("event types loaded"); // saved in $store.getters.get EventTypes
        }
      });
      loadData();
    },

    handleEnterKey() {
      if (this.currentStep === 1 && this.hasGuestCount) {
        this.nextStep();
      } else if (this.currentStep === 2 && this.hasBudget) {
        this.nextStep();
      }
    },
  },
  computed: {
    hasSelectedCuisine() {
      return this.eventDetails.tags.length > 0;
    },
    hasGuestCount() {
      return this.eventDetails.guestCount && this.eventDetails.guestCount > 0;
    },
    hasBudget() {
      return (
        this.eventDetails.budgetPerPerson &&
        this.eventDetails.budgetPerPerson > 0
      );
    },
    hasSelectedAtmosphere() {
      return this.eventDetails.eventAtmosphere != null;
    },
    averageTotal() {
      const totalBudget =
        this.eventDetails.guestCount * this.eventDetails.budgetPerPerson;
      return totalBudget.toFixed(2); // Adjust the formatting as needed
    },
  },
  mounted() {
    if (this.$store.getters.getQuestionnaireTags.length == 0) {
      this.loadTags();
    }
    if (this.$store.getters.getEventTypes.length == 0) {
      this.loadEventTypes();
    }
  },
};
</script>


<style  scoped>
.disabled {
  pointer-events: none; /* Disable pointer events */
  opacity: 0.5; /* Apply visual indication of disabled state */
}
.sloganTitle {
  font-size: 30px;
}
.main_questionnaire {
  margin-bottom: 60px;
  text-align: center;
}
.question_step a {
  cursor: pointer;
}
.question_summary {
  list-style: none;
}
.error_msg {
  color: white;
}
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.step {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #edeaea;
}
.active .circle {
  background-color: #ffab90;
  color: white;
}
.completed .circle {
  background-color: var(--dark-orange); 
  color: white;
}
.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 42%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}
.step.active:not(:last-child)::after,
.step.completed:not(:last-child)::after {
  background-color: var(--dark-orange); 
}
.btn-bg {
  cursor: pointer;
}
.section_active {
  background-color: white;
  color: var(--dark-orange); 
}
</style>