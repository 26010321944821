import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetClientDates = () => {

  const loadedData = ref([])
  const data_error = ref(null)

  const loadData = async () => {
    try {
      let apiUrl = `${baseUrl}products/api/order_dates/`
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available home products')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedData.value = res.data
          store.commit('setClientDates',  res.data);
        }else{
          console.log('unable to load client shop dates')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loadedData, data_error, loadData }
}

export default GetClientDates