<template>
    <div>
        <div class="contact-page-head">
            <h2>How can we help you</h2>
            <p>Please give as much detail as possible so we can answer your query.</p>
        </div>
        <div class="row">
            <form @submit.prevent="submit_contact_us">
                <div class="col-md-11" style="padding:0;">
                    <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                            :class="{ 'input--filled': userData.first_name }">
                            <input class="input__field input__field--nariko" type="text" id="input-1"
                                style="background-color:transparent;" v-model="userData.first_name" required />
                            <label class="input__label input__label--nariko" for="input-1"> <span
                                    class="input__label-content input__label-content--nariko">First name</span>
                            </label>
                        </span> </div>
                    <!--Last name-->
                    <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                            :class="{ 'input--filled': userData.last_name }">
                            <input class="input__field input__field--nariko" type="text" id="input-2"
                                style="background-color:transparent;" v-model="userData.last_name" required />
                            <label class="input__label input__label--nariko" for="input-2"> <span
                                    class="input__label-content input__label-content--nariko">Last name</span>
                            </label>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                    <!--Email Address-->
                    <div class="col-sm-6 col-md-12 black"> <span class="input input--nariko"
                            :class="{ 'input--filled': userData.email }">
                            <input class="input__field input__field--nariko" type="email" id="input-4"
                                v-model="userData.email" style="background-color:transparent;" required />
                            <label class="input__label input__label--nariko" for="input-4">
                                <span class="input__label-content input__label-content--nariko">
                                    Email Address
                                </span>
                            </label>
                        </span>
                    </div>
                    <!--Phone Number-->
                    <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                            :class="{ 'input--filled': userData.phone }">
                            <input class="input__field input__field--nariko" type="text" id="input-3"
                                style="background-color:transparent;" v-model="userData.phone" required />
                            <label class="input__label input__label--nariko" for="input-3"> <span
                                    class="input__label-content input__label-content--nariko">Phone
                                    Number</span>
                            </label>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                    <!--Message-->
                    <div class="col-md-12 message"> <span class="input input--nariko black">
                            <textarea class="input__field input__field--nariko textarea" type="text" id="input-5"
                                style="background-color:transparent;" v-model="userData.msg" required></textarea>
                            <label class="input__label input__label--nariko" for="input-5">
                                <span class="input__label-content input__label-content--nariko" style="color:#191919;">
                                    Message
                                </span>
                            </label>
                        </span>
                    </div>

                    <div class="col-md-12 text-right">
                        <button class="ateeka_submit" type="submit">Send</button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </form>
            <div class="col-md-1"></div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
export default {
    name: "User_contact_form",
    data() {
        return {
            userData: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                msg: ''
            }
        };
    },
    methods: {
        async submit_contact_us() {
            const baseUrl = process.env.VUE_APP_API_BASE_URL;
            let link = `${baseUrl}users/api/users/login/`
            console.log(link);
            this.raiseAlert('success', 'We have received you message successfully !')
        }
    }
}
</script>
