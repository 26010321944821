import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetBanner = () => {

  const loadedBanner = ref([])
  const data_error = ref(null)

  const loadBanner = async() => {
    try {
      let apiUrl = `${baseUrl}website/api/banner/`;
      let response = await fetch(apiUrl);

      if (!response.ok) {
        throw Error('No available home products');
      }

      let data = await response.json();

      if (data.success) {
        loadedBanner.value = data.data;
        console.log('Data was fetched from website banner:')
        console.log(loadedBanner.value)
      } else {
        console.log('Unable to load menu categories');
        console.log(data);
      }
    }
    catch (err) {
      data_error.value = err.message
    }
  }
 
  return {
    loadedBanner,
    loadBanner,
    data_error,
  };
}

export default GetBanner