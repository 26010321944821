<template>
    <div class="success-payment">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase.</p>
      <p>Your order will be processed shortly.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SuccessPayment',
  };
  </script>
  
  <style>
  .success-payment {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 400px;
    background-color: #f0f8ff; /* Light Blue */
  }
  
  .success-payment h1 {
    color: #2e8b57; /* Sea Green */
  }
  
  .success-payment p {
    color: #333;
  }
  </style>
  