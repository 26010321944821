<template>
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <div class="product-cell-wrapper mz_mobile">
      <div class="product-cell">
        <div
          class="product-image"
          :style="{
            backgroundImage:
              'url(' +
              (prod && prod.image
                ? prod.image
                : '/theme_1/theme_restora/img/home-2-1.jpg') +
              ')',
          }"
        >
          <span class="quick_add_to_cart">
            <i class="fa fa-cart-plus" aria-hidden="true"></i>
          </span>
        </div>
        <div class="product-details">
          <b class="menu-b" :class="{ 'black-text': theme === 'black' }">{{
            prod && prod.name ? prod.name : "Baked Potato Pizza"
          }}</b>

          <div v-if="prod && prod.tag.length > 0">
            <p class="menu-p" :class="{ 'black-text': theme === 'black' }">
              <span v-for="(tag, index) in prod.tag" :key="index">
                {{ tag.name
                }}<span v-if="index < prod.tag.length - 1"> / </span>
              </span>
            </p>
            <p class="menu-p price">
              <span class="content-subhead menu-span"
                >€{{ prod.price }}</span
              >
            </p>
          </div>
          <div v-else>
            <p class="menu-p" :class="{ 'black-text': theme === 'black' }">
              <span>Suggested</span>
            </p>
            <p class="menu-p price">
              <span class="content-subhead menu-span"
                >€{{ prod.price }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    prod: null,
    theme: null,
  },
  mounted() {
  },
};
</script>

<style scoped>
.product-cell-wrapper {
  margin: 10px; /* Adjust margin as needed */
}

.product-cell {
  position: relative;
  overflow: hidden;
  border-radius: 8px; /* Adjust border radius as needed */
}


.quick_add_to_cart {
  position: absolute;
  bottom: 10px; /* Adjust bottom spacing as needed */
  right: 10px; /* Adjust right spacing as needed */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Adjust background color and opacity as needed */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: var(--dark-orange); 
}

.product-details .menu-b {
  font-size: 14px;
  float: left;
  margin-top: 15px;
  height: 50px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: left;
}
.product-details .menu-p {
  width: 100%;
  height: 52px;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
}
.product-details .menu-span {
  margin-top: 0px;
}
.product-details .menu-p.price{
height: auto;
margin-top: -30px;
}
.product-details .price span{
  float: left;
}
.black-text {
  color: black;
}

/* Add any additional styles as needed */
</style>
