import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetTags = () => {

  const loadedData = ref([])
  const data_error = ref(null)

  const loadTagsData = async (onlyQuestionnaire,onlyFilters) => {
    try {
      let apiUrl = `${baseUrl}products/api/tags/`;
      let queryParams = [];
      if (onlyQuestionnaire) {
        queryParams.push(`questionnaire=${onlyQuestionnaire}`);
      }

      if(onlyFilters){
        queryParams.push(`filters=${onlyFilters}`)
      }
      if (queryParams.length > 0) {
        apiUrl += `?${queryParams.join('&')}`;
      }
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available tags')
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedData.value = res.data;
          if (onlyQuestionnaire) {
            store.commit('setQuestionnaireTags', res.data);
          } else {
            store.commit('setTags', res.data);
          }

        } else {
          console.log('unable to load tags')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loadedData, data_error, loadTagsData }
}

export default GetTags