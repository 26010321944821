import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CatermateView from '@/views/CatermateView'
// import ShopView from '@/views/ShopView' todo add this with regular ecommerce
import ClientShop from '@/views/Client/ClientShop'
import AboutView from '@/views/AboutView'
import MenuView from '@/views/MenuView'
import User from '@/views/Client/User'
import ClientCartPage from '@/views/Client/ClientCartPage'
import SuccessPayment from '@/views/SuccessPayment';
import FailedPayment from '@/views/FailedPayment';
import Contact from '@/views/Contact'

const routes = [
  {
    path: '/',
    name: 'home_page',
    component: HomeView
  },
  {
    path: '/events',
    name: 'events',
    component: HomeView
  },
  // {
  //   path: '/shop',
  //   name: 'shop',
  //   component: ShopView
  // },
  {
    path: '/shop_client',
    name: 'client_shop_page',
    component: ClientShop
  },
  {
    path: '/contact_us',
    name: 'contact_us_page',
    component: Contact
  },
  {
    path: '/user',
    name: 'user_page',
    component: User
  },
  {
    path: '/my_cart',
    name: 'client_cart_page',
    component: ClientCartPage
  },

  {
    path: '/catermate',
    name: 'catermate_page',
    component: CatermateView
  },
  {
    path: '/about',
    name: 'about_page',
    component: AboutView
  },
  {
    path: '/menu',
    name: 'menu_page',
    component: MenuView
  },
  {
    path: '/menu',
    name: 'menu_page',
    component: MenuView
  },
  { path: '/success', component: SuccessPayment },
  { path: '/failed', component: FailedPayment },



]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { top: 0 };
  // },
})
// Redirect from HTTPS to HTTP
// if (window.location.protocol === 'https:') {
//   window.location.href = 'http://' + window.location.host + window.location.pathname;
// }

export default router
