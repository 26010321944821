<template>
    <div>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" />
        <div id="wrapper">
            <HeaderComponent />
            <page_header title="My account" subtitle="Let's Get Started with Your Delicious Journey!"
                pageName="My account" />

            <div class="container padd-50">
                <div class="col-md-8 contact-page-head-border">
                    <Create_account v-if="formType == 'createAccount'" />
                    <Login v-if="formType == 'login'" />
                    <Reset_password v-if="formType == 'reset_password'" />
                </div>

                <div class="col-md-4">
                    <div class="row margin-top">
                        <div class="col-md-offset-2 col-md-10">
                            <div class="contact-page-head">
                                <h2>Usefull links</h2>
                                <div class="contact-head-u btn-bg"></div>
                            </div>
                            <div class="related_links">
                                <p>I don't have an account <a @click="change_form('createAccount')">Create Account</a>
                                </p>
                                <p>I ALready have an account <a @click="change_form('login')">Login</a></p>
                                <p>I forgot my password <a @click="change_form('reset_password')">Reset password</a></p>
                            </div>
                            <div class="contact-det">
                                <div class="contact-icon"><i class="flaticon-envelope"></i></div>
                                <span>EMAIL <br />
                                    <p> <a class="mail_btn" href="mailto:info@chaifingdish.com">
                                            info@chaifingdish.com
                                        </a></p>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <FooterComponent />
    </div>

</template>

<script>
import HeaderComponent from "@/views/components/Header";
import page_header from "@/views/components/page_header";
import FooterComponent from "@/views/components/Footer";
import Create_account from "@/views/components/Create_account";
import Login from "@/views/components/Login";
import Reset_password from "@/views/components/Reset_password"
export default {
    name: "User",
    components: {
        HeaderComponent,
        page_header,
        FooterComponent,
        Create_account, Login, Reset_password
    },
    data() {
        return {
            formType: 'login'
        }
    },
    methods: {
        change_form(type) {
            this.formType = type;
        }
    }
};
</script>
