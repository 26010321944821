import { ref } from 'vue'
import store from '@/store';
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const GetData = () => {

  const loadedData = ref([])
  const data_error = ref(null)

  const loadTableData = async (table, paramTxt = null, paramValue=null) => {
    try { //categories
      let apiUrl = `${baseUrl}products/api/${table}/`
      let params = {}
      if (paramTxt && paramValue) {
        params[paramTxt]  = paramValue ;
      }
      let requestOptions = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params), 
      };
      let data = await fetch(apiUrl,requestOptions)
      if (!data.ok) {
        throw Error('no available data for ' + table)
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedData.value = res.data
        } else {
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loadedData, data_error, loadTableData }
}

export default GetData