<template>
  <div class="failed-payment">
    <h1>Payment Failed</h1>
    <p>Sorry, your payment was not successful.</p>
    <p>Please try again or contact support.</p>
  </div>
</template>

<script>
export default {
  name: 'FailedPayment',
};
</script>

<style>
.failed-payment {
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 400px;
  background-color: #ffe4e1; /* Misty Rose */
}

.failed-payment h1 {
  color: #ff6347; /* Tomato */
}

.failed-payment p {
  color: #333;
}
</style>
