<template>
  <div>
    <div class="container-fluid footer-3-bg ateeka_continer_fluid">
      
      <div class="container-fluid ateeka_continer_fluid">
        <div class="footer-3-bg-black">
          <div class="container padd-50">
            <div class="col-md-12 text-center footer">
              <div class="foot-3-logo">
                <div class="foot-logo">
                  <a href="index-leading-page.html">

                    <img id="logo_img" src="@assets/img/logo.png" alt="logo" />
                  </a>
                </div>
              </div>
              <br />
              <p>Chaifing Dish!! Now That Your Tastes Have Grown Up!</p>
            </div>
            <div class="col-md-12 text-center footer-3-btm">
              <h2 class="text-uppercase">CONTACT INFORMATION</h2>
              <div class="col-sm-6 col-md-4 footer-3-mar-tp text-center">
                <i class="flaticon-phone-call"></i>
                <h4>PHONE</h4>
                <span class="btn-bg"></span>
                <br />
                <p>+32 467 65 47 04</p>
                <br/>
                <p>+32 466 21 05 67</p>
              </div>
              <div class="col-sm-6 col-md-4 footer-3-mar-tp text-center">
                <i class="flaticon-placeholder"></i>
                <h4>ADDRESS</h4>
                <span class="btn-bg"></span>
                <br />
                <p>Watermael-Boitsfort 1170, Brussels, Belgium.</p>
              </div>
              <div class="col-sm-12 col-md-4 footer-3-mar-tp text-center">
                <i class="flaticon-envelope"></i>
                <h4>EMAIL</h4>
                <span class="btn-bg"></span>
                <br />
                <p> <a class="mail_btn" href="mailto:info@chaifingdish.com">
                    info@chaifingdish.com
                  </a></p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="col-md-12 text-center footer" v-if="showSocialMedia">
              <h3 class="text-uppercase foot-logo-h3">Share The Taste</h3>
              <ul>
                <li>
                  <a class="footer-soc-a" href="#">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a class="footer-soc-a" href="#">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a class="footer-soc-a" href="#">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a class="footer-soc-a" href="#">
                    <i class="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                </li>
                <div class="clearfix"></div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid footer-pay-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p class="footer-btm-p">
              ©2024 <b class="content-subhead">ChaifingDish</b>. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
  mounted() {
    console.log('Footer mounted')

    // if (sectionId) {
    this.scrollToSection(this.$route.query.section)
    // }
  },
  methods: {
  },
  data(){
    return {
      showSocialMedia:false
    }
  }
}

</script>


<style scoped>
.foot-logo img {
  height: 70px;
  width: auto;
}
</style>