<template>
  <!-- <div> -->
  <!-- <div class="the_product" todo check if we need to use this way of classing v-for="prod in products" :key="prod.id" :class="productCellClass"> -->
    <div class="the_product" v-for="prod in products" :key="prod.id">
    <div class="item shop text-center">
      <figure>
        <img alt="1-image" :src="prod.image" class="img-responsive" />
      </figure>
      <h2 class="menu-2-b prodName">{{ prod.name }}</h2>
      <p class="menu-2-p prodDetails" style="margin-top: 0px">
        {{ prod.description }}
      </p>
      <div class="divider"></div>
      <div class="price">
        <!-- <span class="menu-2-span content-subhead">{{ prod.price }}€</span> -->
        <span class="priceLabel">{{ prod.price }}€</span>
        <span class="shop-a content-subhead add_icon" @click="HandleProductClick(prod)" data-toggle="modal"
          data-target="#myModal"><i class="fa fa-plus-square" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>


<script>
import { watch } from "vue";

export default {
  name: "Product_client_shop",
  emits: ['add_to_cart_show_popup'],
  props: {
    products: null,
  },
  mounted() { },
  methods: {
    HandleProductClick(product) {
      this.$emit("add_to_cart_show_popup", product); // Emit an event with the product to show popup, and add to cart
    },
  },
  computed: {

    productCellClass() {
      return [
        'col-sm-6 col-xs-12',
        {
          'col-md-3': this.$store.getters.isCartEmpty,
          'col-md-4': !this.$store.getters.isCartEmpty
        }
      ];
    }
  }
};
</script>

<style scoped>
.product-cell-wrapper {
  margin: 10px;
  /* Adjust margin as needed */
}

.product-cell {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  /* Adjust border radius as needed */
}



.quick_add_to_cart {
  position: absolute;
  bottom: 10px;
  /* Adjust bottom spacing as needed */
  right: 10px;
  /* Adjust right spacing as needed */
  background-color: rgba(255,
      255,
      255,
      0.8);
  /* Adjust background color and opacity as needed */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: var(--dark-orange);
}

.product-details .menu-b {
  font-size: 14px;
  float: left;
  margin-top: 15px;
  height: 50px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: left;
}

.product-details .menu-p {
  width: 100%;
  height: 52px;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
}

.product-details .menu-span {
  margin-top: 0px;
}

.product-details .menu-p.price {
  height: auto;
  margin-top: -30px;
}

.product-details .price span {
  float: left;
}

.black-text {
  color: black;
}

/* Add any additional styles as needed */
.the_product {
  width: 210px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
</style>
