<template>
    <div>
        <!-- class="menu-table" -->
        <table v-if="loadedMenuTags" class="col-md-12 element-table menu-table">
            <thead>
                <tr>
                    <th>Product or dish containing allergies</th>
                    <th v-for="tag in loadedMenuTags" :key="tag.id">
                        <div class="tag-container">
                            <div class="tag-name">{{ tag.name }}</div>
                            <img :src="tag.image" alt="Tag Image" class="tag-image">
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in loadedMenuItems" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td v-for="tag in loadedMenuTags" :key="tag.id">
                        <span v-if="item.tags.find(itemTag => itemTag.id === tag.id)">
                            <span class="tags_match fa fa-check-square-o"></span>
                        </span>
                        <span v-else></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import GetMenu from "@/composables/GetMenu"
export default {
    setup() {
        const { loadedMenuItems, loadedMenuTags, data_error, loadMenuItems, loadMenuTags } = GetMenu()
        // Load menu items and tags when component is mounted
        onMounted(async () => {
            try {
                await loadMenuItems()
                await loadMenuTags()

            } catch (error) {
                data_error.value = error.message
            }
        })
        return { loadedMenuItems, loadedMenuTags, data_error }
    }
}
</script>

<style>
.menu-table th,
.menu-table td {
    text-align: center;

    .tags_match {
        font-size: 2em;
    }
}

.tag-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tag-name {
        margin-bottom: 5px;
    }

    .tag-image {
        width: 50px;
        height: 50px;
    }
}
</style>