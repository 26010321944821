<template>
    <div>
        <div class="contact-page-head">
            <h2>Reset my password</h2>
            <p>Simply fill the form with the new password with the verification token you received by email</p>
        </div>
        <div class="row">
        <form @submit.prevent="reset_password">
            <div class="col-md-11" style="padding:0;">
                <!--Email Address-->
                <div class="col-sm-6 col-md-12 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.email }">
                        <input class="input__field input__field--nariko" type="email" id="input-4"
                            v-model="userData.email" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-4"> <span
                                class="input__label-content input__label-content--nariko">Email
                                Address</span>
                        </label>
                    </span> </div>
                <div class="clearfix"></div>

                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.new_password }">
                        <input class="input__field input__field--nariko" type="password" id="input-6"
                            v-model="userData.new_password" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-6"> <span
                                class="input__label-content input__label-content--nariko">New Password</span>
                        </label>
                    </span> </div>
                    <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.token }">
                        <input class="input__field input__field--nariko" type="text" id="input-7"
                            v-model="userData.token" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-7"> <span
                                class="input__label-content input__label-content--nariko">Validation Token</span>
                        </label>
                    </span> </div>
                <div class="col-md-12 text-right">
                    <button class="ateeka_submit" type="submit">Change password</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
        <div class="col-md-1"></div>
    </div>
    </div>
   
</template>

<script>
import axios from 'axios';
export default {
    name: "User_rseset_password",
    data() {
        return {
            userData: {
                email: '',
                new_password: '',
                token:''
            }
        };
    },
    methods: {
        async reset_password() {
            const baseUrl = process.env.VUE_APP_API_BASE_URL;
            let link = `${baseUrl}users/api/reset_password/`
            console.log(link);
            try {
                await axios.post(link, this.userData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                        this.raiseAlert('success', this.$lang._('password_updated'))
                        this.navigateTo('shop_client');
                    } else {
                        console.log('Unexpected status:', response.status);
                        this.raiseAlert('', 'Invalid request for creating user');
                    }
                })
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    this.raiseAlert('', error.response.data.error);
                } else if (error.request) {// The request was made but no response was received
                    console.log(error.request);
                    this.raiseAlert(false, 'No response from server');
                } else {
                    console.log(error);
                    this.raiseAlert(false, 'Error in request setup');
                }

            }
        }
    }
}
</script>
