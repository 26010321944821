// LanguageService.js
import { ref, readonly } from 'vue';

const languages = ['en', 'fr']; // Add more languages as needed
const defaultLanguage = 'en';

const currentLanguage = ref(defaultLanguage);
let translations = {};

export default {
  currentLanguage: readonly(currentLanguage),

  async init() {
    try {
      // Load translations dynamically based on the current language
      for (const lang of languages) {
        const translationModule = await import(`./${lang}.json`);
        translations[lang] = translationModule.default;
      }
      return true; // Indicate that initialization was successful
    } catch (error) {
      console.error('Error initializing language service:', error);
      return false; // Indicate that initialization failed
    }
  },

  setLanguage(language) {
    if (languages.includes(language)) {
      currentLanguage.value = language;
    } else {
      console.error(`Language '${language}' is not supported.`);
    }
  },

  getLang(){
    console.log(currentLanguage.value)
    return currentLanguage.value;
  },

  _(key) {
    // Fetch translation based on the current language and key
    // return translations[currentLanguage.value][key] || key;
     // Convert key to lowercase
     const lowerCaseKey = key.toLowerCase();
     // Fetch translation based on the current language and key
     const translation = translations[currentLanguage.value][lowerCaseKey];
     // If translation exists, return it
     if (translation) {
         return translation;
     }
     // If translation doesn't exist, return the original key
     return key;
  },
  
};
