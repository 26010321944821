<template>
    <div>
        <div class="contact-page-head">
            <h2>Create account</h2>
            <p>Provide your current details, as we will use them to deliver your orders</p>
        </div>
        <div class="row">
        <form @submit.prevent="createUser">
            <div class="col-md-11" style="padding:0;">

                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.first_name }">
                        <input class="input__field input__field--nariko" type="text" id="input-1"
                            style="background-color:transparent;" v-model="userData.first_name" required />
                        <label class="input__label input__label--nariko" for="input-1"> <span
                                class="input__label-content input__label-content--nariko">First name</span>
                        </label>
                    </span> </div>
                <!--Last name-->
                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.last_name }">
                        <input class="input__field input__field--nariko" type="text" id="input-2"
                            style="background-color:transparent;" v-model="userData.last_name" required />
                        <label class="input__label input__label--nariko" for="input-2"> <span
                                class="input__label-content input__label-content--nariko">Last name</span>
                        </label>
                    </span> </div>
                <div class="clearfix"></div>
                <!--Phone Number-->
                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.phone }">
                        <input class="input__field input__field--nariko" type="text" id="input-3"
                            style="background-color:transparent;" v-model="userData.phone" required />
                        <label class="input__label input__label--nariko" for="input-3"> <span
                                class="input__label-content input__label-content--nariko">Phone
                                Number</span>
                        </label>
                    </span> </div>
                <!--Email Address-->
                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.email }">
                        <input class="input__field input__field--nariko" type="email" id="input-4"
                            v-model="userData.email" style="background-color:transparent;"  required />
                        <label class="input__label input__label--nariko" for="input-4"> <span
                                class="input__label-content input__label-content--nariko">Email
                                Address</span>
                        </label>
                    </span> </div>
                <div class="clearfix"></div>

                <div class="col-sm-6 col-md-6 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.password }">
                        <input class="input__field input__field--nariko" type="password" id="input-6"
                            v-model="userData.password" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-6"> <span
                                class="input__label-content input__label-content--nariko">Password</span>
                        </label>
                    </span> </div>
                <div class="col-md-12 text-right">
                    <button class="ateeka_submit" type="submit">Submit</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
        <div class="col-md-1"></div>
    </div>
    </div>
   
</template>

<script>
import axios from 'axios';
export default {
    name: "User_create_account",
    data() {
        return {
            userData: {
                email: '',
                phone: '',
                first_name: '',
                last_name: '',
                password: ''
            }
        };
    },
    methods: {
        async createUser() {
            const baseUrl = process.env.VUE_APP_API_BASE_URL;
            let link = `${baseUrl}users/api/create/`
            console.log(link);
            try {
                await axios.post(link, this.userData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 201) {
                        console.log(response.data);
                        this.raiseAlert('success', 'User created successfully !')
                    } else {
                        console.log('Unexpected status:', response.status);
                        this.raiseAlert('', 'Invalid request for creating user');
                    }
                })
            } catch (error) {
                console.log('error create account')
                console.log(error)
                if (error.response) {
                    console.log(error.response.data);
                    this.raiseAlert('', error.response.data.error);
                } else if (error.request) {// The request was made but no response was received
                    console.log(error.request);
                    this.raiseAlert(false, 'No response from server');
                } else {
                    console.log(error);
                    this.raiseAlert(false, 'Error in request setup');
                }

            }
        }
    }
}
</script>
